import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ControlContainer, UntypedFormArray, UntypedFormGroup, NgForm, Validators, UntypedFormBuilder } from '@angular/forms';
import { AssetDetailsService } from '../asset-details/asset-details.service';
import { FireOrderOptions } from '../shared/model/asset.model';
import { RefService } from '../shared/services/ref.service';

@Component({
  selector: 'app-fire-order',
  templateUrl: './fire-order.component.html',
  styleUrls: ['./fire-order.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FireOrderComponent implements OnInit, AfterViewInit {
  curtailOpenState: boolean = false;
  restoreOpenState: boolean = false;

  get stepForm(): UntypedFormGroup {
    return this._fb.group({
      controls: [[], Validators.required],
      controlRetries: [0],
      controlRetryDelay: [0],
      preCheckDelay: [0],
      checkRetries: [0],
      checkRetryDelay: [0],
      postStepDelay: [0],
    });
  }

  constructor(
    private assetDetailsService: AssetDetailsService,
    private _fb: UntypedFormBuilder,
    private refService: RefService,
  ) {}

  @Input() assetForm: UntypedFormGroup;

  get fireOrder() {
    return this.assetForm.get('fireOrder');
  }

  get commands() {
    return this.assetForm.get('commands');
  }

  get curtail() {
    return this.fireOrder?.get('curtail') as UntypedFormArray;
  }

  get restore() {
    return this.fireOrder?.get('restore') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.assetDetailsService.addFireOrderStep$.subscribe((fireOrderOptions: FireOrderOptions) => {
      const { generate } = fireOrderOptions;
      if ((this.curtail.length == 0 && this.restore.length == 0 && this.commands.value.length > 1) || generate) {
        // there are commands that are not in fire order, lets add them.
        for (let control of this.commands.value) {
          this.addFireOrderStep(control);
        }
      } else {
        const { control } = fireOrderOptions;
        this.addFireOrderStep(control);
      }
    });

    this.assetDetailsService.removeFireOrderStep$.subscribe((fireOrderOptions: FireOrderOptions) => {
      const { control } = fireOrderOptions;
      let curtailRemoveIndexes = [];
      let restoreRemoveIndexes = [];

      let cur = this.curtail as UntypedFormArray;
      let res = this.restore as UntypedFormArray;

      this.removeControl(cur, control);
      this.removeControl(res, control);

      curtailRemoveIndexes = this.getEmptySteps(cur);
      restoreRemoveIndexes = this.getEmptySteps(res);

      for (let index of curtailRemoveIndexes.reverse()) {
        cur.removeAt(index);
      }

      for (let index of restoreRemoveIndexes.reverse()) {
        res.removeAt(index);
      }
    });

    this.refService.setChannels();
    this.refService.setEqualityOperators();
    this.refService.setFireOrderCheckType();
    this.refService.setReportingIntervals();
  }

  addFireOrderStep(control: string) {
    const cur = this.curtail as UntypedFormArray;
    const res = this.restore as UntypedFormArray;
    const controlVal = control ? [control] : [];

    const curtailStep = this.stepForm;
    curtailStep.get('controls').setValue(controlVal);
    const restoreStep = this.stepForm;
    restoreStep.get('controls').setValue(controlVal);

    cur.push(curtailStep);
    res.insert(0, restoreStep);
  }

  removeControl(fireOrderType: UntypedFormArray, control: string) {
    for (let step of fireOrderType.controls) {
      const stepControls = step.get('controls');
      if (stepControls.value.includes(control)) {
        const controlIndex = stepControls.value.findIndex((stepControl) => {
          return stepControl === control;
        });

        const newControl = stepControls.value;
        newControl.splice(controlIndex, 1);
        stepControls.setValue(newControl);
      }
    }
  }

  getEmptySteps(fireOrderType: UntypedFormArray) {
    let stepIndex = 0;
    const emptySteps: number[] = [];
    if (fireOrderType.value && fireOrderType.value.length > 0) {
      for (let step of fireOrderType.controls) {
        const stepControls = step.get('controls');
        if (stepControls.value.length === 0) {
          emptySteps.push(stepIndex);
        }
        stepIndex++;
      }
    }

    return emptySteps;
  }

  ngAfterViewInit(): void {}

  drop(event: CdkDragDrop<string[]>, fireOrderType: UntypedFormArray) {
    const fireOrderStep = fireOrderType.get(`${event.previousIndex}`);
    fireOrderType.removeAt(event.previousIndex);
    fireOrderType.insert(event.currentIndex, fireOrderStep);
  }

  addFireOrder() {
    this.assetDetailsService.addFireOrderStep$.next({ generate: true });
  }

  hasCommands() {
    return this.commands.value.length > 0;
  }
}
