import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AssetsService } from '../../shared/services/assets.service';

export interface AssetData {
  assetId: string;
}

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
  dialogState = 'DEFAULT';

  constructor(
    private router: Router,
    private assetsService: AssetsService,
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AssetData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  async handleDelete() {
    console.log('DELETE', this.data);
    try {
      this.dialogState = 'DELETING';
      await this.assetsService.deleteAsset(this.data.assetId);
      this.dialogState = 'DELETE_SUCCESS';
      setTimeout(() => {
        this.dialogRef.close();
        this.router.navigate([`/`], {});
      }, 2000);
    } catch (e) {
      this.dialogState = 'DELETE_FAILED';
      console.log(e);
      // let errorMessage = '';
      // if (e.error && e.error.message) {
      //     errorMessage = e.error.message;
      // } else {
      //     errorMessage = 'ERR_BAD_REQUEST';
      // }
      setTimeout(() => {
        this.dialogRef.close();
      }, 2000);
    }
  }
}
