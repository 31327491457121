<section class="fire-order-section" *ngIf="fireOrder" [formGroup]="assetForm">
  <mat-expansion-panel
    (opened)="curtailOpenState = true"
    (closed)="curtailOpenState = false"
    togglePosition="before"
    formGroupName="fireOrder"
    expanded="true"
    *ngIf="curtail && curtail.value.length > 0"
    data-automation="curtail"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4 class="fire-order-subheader">{{ "asset.create.fire_order.curtail" | translate }}</h4>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="fire-order-step-wrapper" formArrayName="curtail" cdkDropList (cdkDropListDropped)="drop($event, curtail)" data-automation="curtail">
      <ng-container *ngFor="let step of curtail.controls; let stepIndex = index">
        <app-fire-order-step [index]="stepIndex" [step]="step"></app-fire-order-step>
      </ng-container>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel
    (opened)="restoreOpenState = true"
    (closed)="restoreOpenState = false"
    togglePosition="before"
    formGroupName="fireOrder"
    expanded="true"
    data-automation="restore"
    *ngIf="restore && restore.value.length > 0"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4 class="fire-order-subheader">{{ "asset.create.fire_order.restore" | translate }}</h4>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="fire-order-step-wrapper" formArrayName="restore" cdkDropList (cdkDropListDropped)="drop($event, restore)" data-automation="restore">
      <ng-container *ngFor="let step of restore.controls; let stepIndex = index">
        <app-fire-order-step [index]="stepIndex" [step]="step"></app-fire-order-step>
      </ng-container>
    </div>
  </mat-expansion-panel>
</section>
<div *ngIf="curtail && curtail.value.length === 0 && restore && restore.value.length == 0" data-automation="no-fire-order-message" class="form-group">
  <button class="x-btn-primary" (click)="addFireOrder()" [disabled]="!hasCommands()" data-automation="generateFireOrder">{{ 'asset.create.fire_order.generate' | translate }}</button>
</div>

