import { Component, Input, OnInit } from '@angular/core';
import { Check, StepInterface } from '../../shared/model/asset.model';
import { AssetDetailsService } from '../../asset-details/asset-details.service';
import { RefData, ReportingInterval } from '../../shared/model/ref-data.model';
import { RefService } from '../../shared/services/ref.service';

@Component({
  selector: 'app-fire-order-view-step',
  templateUrl: './fire-order-view-step.component.html',
  styleUrls: ['./fire-order-view-step.component.scss'],
})
export class FireOrderViewStepComponent implements OnInit {
  stepOpenState = true;
  controlElements: any[] = [];
  reportingIntervals: ReportingInterval[];
  equalityOperators: RefData[];
  fireOrderCheckTypes: RefData[];
  channels: RefData[];
  spaceGroups: any;
  constructor(private assetDetailsService: AssetDetailsService, private refService: RefService) {}

  @Input() step: StepInterface;
  @Input() index: number;

  get controls() {
    return this.step.controls || [];
  }

  get controlRetries() {
    return this.step.controlRetries || 0;
  }

  get controlRetryDelay() {
    return this.step.controlRetryDelay || 0;
  }

  get checkRetries() {
    return this.step.checkRetries || 0;
  }

  get checkRetryDelay() {
    return this.step.checkRetryDelay || 0;
  }

  get postStepDelay() {
    return this.step.postStepDelay || 0;
  }

  get preCheckDelay() {
    return this.step.preCheckDelay || 0;
  }

  get checks() {
    return this.step.checks;
  }

  getCheckType(check: Check) {
    if (check && check.type && this.fireOrderCheckTypes) {
      const fireOrderCheckType = this.fireOrderCheckTypes.find(
        (checkType) => checkType.code.toLocaleLowerCase() === check.type.toLowerCase(),
      );
      if (fireOrderCheckType) {
        return fireOrderCheckType.displayLabel;
      }
    }
    return '';
  }

  getSpace(check: Check) {
    let foundSpace: any = {};
    if (check && check.spaceId && this.spaceGroups && Object.keys(this.spaceGroups).length > 0) {
      for (let key of Object.keys(this.spaceGroups)) {
        const space = this.spaceGroups[key].find((space) => {
          return space.id === check.spaceId;
        });
        if (space) {
          foundSpace = space;
        }
      }

      if (foundSpace) {
        return foundSpace.displayLabel;
      }
    }
    return '';
  }

  getChannel(check: Check) {
    if (check && check.channelId && this.channels) {
      const channel: any = this.channels.find((channel) => parseInt(channel.id) === parseInt(check.channelId));
      if (channel) {
        return channel.displayLabel;
      }
    }
    return '';
  }

  getPeriod(check: Check) {
    if (check && check.granularity && this.reportingIntervals) {
      const reportingInterval: any = this.reportingIntervals.find(
        (reportingInterval: any) => reportingInterval.code === check.granularity,
      );
      if (reportingInterval) {
        return reportingInterval.displayLabel;
      }
    }
    return '';
  }

  getOperator(check: Check) {
    if (check && check.operator && this.equalityOperators) {
      const operator = this.equalityOperators.find((operator) => operator.code === check.operator);
      if (operator) {
        return operator.displayLabel;
      }
    }
    return '';
  }

  ngOnInit(): void {
    this.assetDetailsService.selectedControlElements$.subscribe((controlElements) => {
      this.controlElements = controlElements;
    });

    this.refService.reportingIntervals$.subscribe((reportingIntervals) => {
      this.reportingIntervals = reportingIntervals;
    });

    this.refService.equalityOperators$.subscribe((equalityOperators) => {
      this.equalityOperators = equalityOperators;
    });

    this.refService.fireOrderCheckTypes$.subscribe((fireOrderCheckTypes) => {
      this.fireOrderCheckTypes = fireOrderCheckTypes;
    });

    this.refService.channels$.subscribe((channels) => {
      this.channels = channels;
    });

    this.assetDetailsService.siteSpaces$.subscribe((spaceGroups: any[]) => {
      this.spaceGroups = spaceGroups;
    });
  }

  get controlDisplayLabel() {
    let stepControlsLabel = '';
    if (this.step && this.controlElements && this.controlElements.length > 0) {
      const controlIds = this.step.controls;
      if (controlIds && controlIds.length > 0) {
        for (let id of controlIds) {
          const controlElement = this.controlElements.find((controlElement) => controlElement.id === id);
          if (controlElement) {
            stepControlsLabel = stepControlsLabel
              ? `${stepControlsLabel}, ${controlElement.displayLabel}`
              : controlElement.displayLabel;
          }
        }
      }
    }

    return stepControlsLabel || '<Control Elements>';
  }
}
