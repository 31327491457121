import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { RefData, ReportingInterval } from '../model/ref-data.model';

@Injectable()
export class RefService {
  fireOrderCheckTypes$: BehaviorSubject<RefData[]>;
  equalityOperators$: BehaviorSubject<RefData[]>;
  reportingIntervals$: BehaviorSubject<ReportingInterval[]>;
  channels$: BehaviorSubject<RefData[]>;
  constructor(private apiService: ApiService) {
    this.fireOrderCheckTypes$ = new BehaviorSubject<RefData[]>([]);
    this.equalityOperators$ = new BehaviorSubject<RefData[]>([]);
    this.channels$ = new BehaviorSubject<RefData[]>([]);
    this.reportingIntervals$ = new BehaviorSubject<ReportingInterval[]>([]);
  }

  async setFireOrderCheckType() {
    try {
      const fireOrderCheckTypes = await this.apiService.get('fire-order-check-types');
      this.fireOrderCheckTypes$.next(convertKeys.toCamel(fireOrderCheckTypes));
    } catch (err) {
      console.log('Could not get fire order check types');
    }
  }

  async setEqualityOperators() {
    try {
      const equalityOperators = await this.apiService.get('equality-operators');
      this.equalityOperators$.next(convertKeys.toCamel(equalityOperators));
    } catch (err) {
      console.log('Could not get equality operators');
    }
  }

  async setChannels() {
    try {
      const channels = await this.apiService.get('channels');
      this.channels$.next(convertKeys.toCamel(channels));
    } catch (err) {
      console.log('Could not get channels');
    }
  }

  async setReportingIntervals() {
    try {
      const reportingIntervals = await this.apiService.get('granularities');
      this.reportingIntervals$.next(convertKeys.toCamel(reportingIntervals));
    } catch (err) {
      console.log('Could not get reporting intervals');
    }
  }
}
