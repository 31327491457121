import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { AssetDetailsService } from '../asset-details/asset-details.service';
import { AssetsService } from '../shared/services/assets.service';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../dialogs/delete/delete-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationsService } from '../shared/services/organizations.service';
import { Asset, FireOrder, StepInterface } from '../shared/model/asset.model';
import { Locale } from '../shared/model/locale.model';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss', '../shared/shared.styles.scss'],
})
export class EditComponent implements OnInit, OnDestroy {
  SUCCESS = 'Created Successfully';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  NOT_CREATED = 'Oops, There was a problem creating your asset';
  REQUIRED = 'required';

  assetId = '';
  subscriptions = [];
  isSubmitting = false;
  isLoadingAsset = false;

  currentSite = null;
  assetOrgId;
  assetForm: UntypedFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl('', Validators.required),
    displayLabels: new UntypedFormControl({}, Validators.required),
    supportedLocales: new UntypedFormControl([new Locale()], Validators.required),
    displayLabel: new UntypedFormControl('', Validators.required),
    site: new UntypedFormControl(null, Validators.required),
    defaultLocale: new UntypedFormControl('', Validators.required),
    timezone: new UntypedFormControl('', Validators.required),
    status: new UntypedFormControl('', Validators.required),
    monitors: new UntypedFormControl([], Validators.minLength(1)),
    performance: new UntypedFormControl([], Validators.minLength(1)),
    submeters: new UntypedFormControl([], Validators.minLength(1)),
    commands: new UntypedFormControl([]),
    includes: new UntypedFormControl([]),
    fireOrder: new UntypedFormGroup({
      curtail: new UntypedFormArray([]),
      restore: new UntypedFormArray([]),
    }),
  });
  readonly mode = 'edit';
  readonly APPPREFIX = 'ast';
  private asset: Asset;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: GlobalAlertService,
    private assetDetailsService: AssetDetailsService,
    private assetsService: AssetsService,
    private organizationsService: OrganizationsService,
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    public _fb: UntypedFormBuilder,
  ) {
    this.translateService.get('asset.notification.updated_successfully').subscribe((result: string) => {
      this.SUCCESS = result;
      this.BAD_REQUEST = this.translateService.instant('asset.notification.bad_request');
      this.NOT_CREATED = this.translateService.instant('asset.notification.not_created');
      this.REQUIRED = this.translateService.instant('asset.validation.required');
    });
  }

  get fireOrderForm() {
    return this.assetForm.get('fireOrder');
  }

  get curtailFormEntries() {
    return this.fireOrderForm.get('curtail') as UntypedFormArray;
  }

  get restoreFormEntries() {
    return this.fireOrderForm.get('restore') as UntypedFormArray;
  }

  ngOnInit() {
    const routeSub = this.route.params.subscribe(async (params) => {
      if (params.id) {
        this.assetId = params.id;
        this.isLoadingAsset = true;
        this.assetDetailsService.selectedAsset.next(this.assetId);
        await this.assetDetailsService.getAssetDetails(params.id);
        if (this.organizationsService.isInit) {
          this.organizationsService.getOrgs(this.assetOrgId);
        }
        this.isLoadingAsset = false;
      }
    });

    const assetSub = this.assetDetailsService.asset$.subscribe(async (asset) => {
      console.log('GET Asset', asset);
      if (asset) {
        const { fireOrder } = asset;

        this.asset = asset;
        this.assetForm.patchValue(asset);
        if (fireOrder) {
          this.populateFireOrder(fireOrder);
        }
        this.assetOrgId = asset.site.orgId || (await this.assetsService.getOrgForSite(this.asset.site.id)).id;
        this.assetForm.get('site').setValue(asset.site.id);
        this.currentSite = asset.site;
        if (asset.site.id) {
          await this.assetDetailsService.getEquipmentForSite(asset.site.id);
        }
      }
    });

    this.subscriptions = [routeSub, assetSub];
  }

  populateFireOrder(fireOrder: FireOrder) {
    const { curtail, restore } = fireOrder;

    this.buildFormStep(curtail, this.curtailFormEntries);
    this.buildFormStep(restore, this.restoreFormEntries);
  }

  getStepForm(step): UntypedFormGroup {
    return this._fb.group({
      controls: [step.controls, Validators.required],
      controlRetries: [step.controlRetries],
      controlRetryDelay: [step.controlRetryDelay],
      preCheckDelay: [step.preCheckDelay],
      checkRetries: [step.checkRetries],
      checkRetryDelay: [step.checkRetryDelay],
      postStepDelay: [step.postStepDelay],
    });
  }

  getCheckForm(check): UntypedFormGroup {
    return this._fb.group({
      type: [check.type, Validators.required],
      spaceId: [check.spaceId, Validators.required],
      channelId: [check.channelId, Validators.required],
      granularity: [check.granularity, Validators.required],
      operator: [check.operator, Validators.required],
      threshold: [check.threshold, [Validators.required, Validators.min(0)]],
    });
  }

  buildFormStep(fireOrderType: StepInterface[], fireOrderTypeForm: UntypedFormArray) {
    if (fireOrderType && fireOrderType.length > 0) {
      for (let step of fireOrderType) {
        const { checks = [] } = step;
        const stepForm = this.getStepForm(step);

        if (checks.length > 0) {
          stepForm.addControl('checks', this._fb.array([]));
        }
        for (let check of checks) {
          const checkForm = this.getCheckForm(check);
          const checksForm = stepForm.get('checks') as UntypedFormArray;
          checksForm.push(checkForm);
        }
        fireOrderTypeForm.push(stepForm);
      }
    }

    return fireOrderTypeForm;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  async handleSubmit() {
    console.log('PUT', this.assetForm.getRawValue());
    if (!this.assetForm.valid) {
      this.messageService.setError(this.REQUIRED);
    } else {
      try {
        this.isSubmitting = true;
        if (this.curtailFormEntries.length === 0 && this.restoreFormEntries.length === 0) {
          this.assetForm.removeControl('fireOrder');
        }
        const response = await this.assetsService.updateAsset(this.assetForm.getRawValue());
        this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX, callbackValue: response.id });
        this.messageService.setSuccess(this.SUCCESS);
        this.isSubmitting = false;
        setTimeout(() => {
          this.assetsService.refetchAssets();
          this.router.navigate([`details/${response.id}/view`], {});
        }, 2000);
      } catch (e) {
        this.isSubmitting = false;
        console.log(e);
        let errorMessage = '';
        if (e.error && e.error.message) {
          errorMessage = e.error.message;
        } else {
          errorMessage = 'ERR_BAD_REQUEST';
        }
        if (errorMessage === 'ERR_BAD_REQUEST') {
          this.messageService.setError(this.BAD_REQUEST);
        } else {
          this.messageService.setError(this.NOT_CREATED);
        }
      }
    }
  }

  openDeleteDialog(): void {
    this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {
        assetId: this.assetId,
      },
    });
  }

  handleCancel() {
    this.router.navigate([`details/${this.assetId}/view`]);
  }
}
