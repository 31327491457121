import { Component, OnDestroy, OnInit } from '@angular/core';
import { AssetsService } from '../shared/services/assets.service';
import { AssetDetailsService } from '../asset-details/asset-details.service';
import { OrganizationsService } from '../shared/services/organizations.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent implements OnInit, OnDestroy {
  loading = true;
  noAssets = false;
  assets;
  subs = [];

  constructor(
    private router: Router,
    private assetsService: AssetsService,
    private assetDetailsService: AssetDetailsService,
    private organizationsService: OrganizationsService,
  ) {
    if (this.organizationsService.isInit) {
      this.organizationsService.getOrgs();
    }
    this.assetDetailsService.selectedAsset.next('');
  }

  navigateToFirstAsset(assets) {
    if (assets && assets.length > 0) {
      this.loading = true;
      this.noAssets = false;
      this.router.navigate([`details/${assets[0].id}/view`], {});
    } else {
      this.loading = false;
      this.noAssets = true;
    }
  }

  async ngOnInit() {
    if (this.assetsService.orgId$.value) {
      // refresh asset list
      await this.assetsService.refetchAssets();
    }
    const assetSub = this.assetsService.assets$.subscribe((assets) => {
      if (this.router.isActive('/', true)) {
        this.assets = assets;
        this.navigateToFirstAsset(assets);
      }
    });
    this.subs.push(assetSub);
  }

  async ngOnDestroy() {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }
}
