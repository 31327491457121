import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormArray, ControlContainer, NgForm } from '@angular/forms';
import { RefService } from '../../shared/services/ref.service';
import { ReportingInterval, RefData } from '../../shared/model/ref-data.model';
import { AssetDetailsService } from '../../asset-details/asset-details.service';

@Component({
  selector: 'app-fire-order-step-check-form',
  templateUrl: './fire-order-step-check-form.component.html',
  styleUrls: ['./fire-order-step-check-form.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FireOrderStepCheckFormComponent implements OnInit {
  reportingIntervals: ReportingInterval[];
  equalityOperators: RefData[];
  fireOrderCheckTypes: RefData[];
  channels: RefData[];
  spaces: any;
  constructor(
    private _fb: UntypedFormBuilder,
    private refService: RefService,
    private assetDetailsService: AssetDetailsService,
  ) {}

  @Input() step: UntypedFormGroup;

  get checkForm(): UntypedFormGroup {
    return this._fb.group({
      type: ['', Validators.required],
      spaceId: ['', Validators.required],
      channelId: ['', Validators.required],
      granularity: ['', Validators.required],
      operator: ['', Validators.required],
      threshold: [0.0, [Validators.required, Validators.min(0)]],
    });
  }

  get type() {
    const type = this.checks.get('type');
    return type;
  }

  get spaceId() {
    return this.checks.get('spaceId');
  }

  get channel() {
    return this.checks.get('channel');
  }

  get period() {
    return this.checks.get('period');
  }

  get operator() {
    return this.checks.get('operator');
  }

  get threshold() {
    return this.checks.get('threshold');
  }

  get checks() {
    return this.step.get('checks') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.refService.reportingIntervals$.subscribe((reportingIntervals) => {
      this.reportingIntervals = reportingIntervals;
    });

    this.refService.equalityOperators$.subscribe((equalityOperators) => {
      this.equalityOperators = equalityOperators;
    });

    this.refService.fireOrderCheckTypes$.subscribe((fireOrderCheckTypes) => {
      this.fireOrderCheckTypes = fireOrderCheckTypes;
    });

    this.refService.channels$.subscribe((channels) => {
      this.channels = channels;
    });

    this.assetDetailsService.siteSpaces$.subscribe((spaces: any) => {
      this.spaces = spaces;
    });
  }

  addCheck() {
    if (!this.checks) {
      this.step.addControl('checks', this._fb.array([]));
    }
    this.checks.push(this.checkForm);
  }

  removeCheck(checkIndex: number) {
    const checks = this.checks;
    checks.removeAt(checkIndex);
    if (this.checks.length === 0) {
      this.step.removeControl('checks');
    }
  }

  compareCheckTypes(a, b) {
    return a.toLowerCase() === b.toLowerCase();
  }

  getNumericChannelId(id) {
    return parseInt(id);
  }
}
