<div id="stepForm" [formGroup]="step">
  <div class="form-group fire-order-group">
    <mat-form-field appearance="outline" floatLabel="always" class="double-width">
      <mat-label>{{ 'asset.create.fire_order.controls' | translate }}</mat-label>
      <mat-select
        required
        formControlName="controls"
        data-automation="controls"
        placeholder="{{ 'asset.create.fire_order.controls' | translate }}"
        multiple
      >
        <mat-option *ngFor="let controlElement of controlElements" [value]="controlElement.id">
          {{ controlElement.displayLabel }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="controls.hasError('required')">
        {{ 'asset.validation.required' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{ 'asset.create.fire_order.control_retries' | translate }}</mat-label>
      <mat-select
        formControlName="controlRetries"
        min="0"
        data-automation="controlRetries"
        placeholder="{{ 'asset.create.fire_order.control_retries' | translate }}"
      >
        <mat-option *ngFor="let num of dropDownValues" [value]="num">
          {{ num }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{ 'asset.create.fire_order.control_retry_delay' | translate }}</mat-label>
      <input
        matInput
        type="number"
        step="1"
        min="0"
        formControlName="controlRetryDelay"
        data-automation="controlRetryDelay"
        placeholder="{{ 'asset.create.fire_order.control_retry_delay' | translate }}"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{ 'asset.create.fire_order.precheck_delay' | translate }}</mat-label>
      <input
        matInput
        type="number"
        step="1"
        min="0"
        formControlName="preCheckDelay"
        data-automation="preCheckDelay"
        placeholder="{{ 'asset.create.fire_order.precheck_delay' | translate }}"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{ 'asset.create.fire_order.check_retries' | translate }}</mat-label>
      <mat-select
        formControlName="checkRetries"
        data-automation="checkRetries"
        placeholder="{{ 'asset.create.fire_order.check_retries' | translate }}"
      >
        <mat-option *ngFor="let num of dropDownValues" [value]="num">
          {{ num }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{ 'asset.create.fire_order.check_retry_delay' | translate }}</mat-label>
      <input
        matInput
        type="number"
        step="1"
        min="0"
        formControlName="checkRetryDelay"
        data-automation="checkRetryDelay"
        placeholder="{{ 'asset.create.fire_order.check_retry_delay' | translate }}"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{ 'asset.create.fire_order.post_step_delay' | translate }}</mat-label>
      <input
        matInput
        type="number"
        step="1"
        min="0"
        formControlName="postStepDelay"
        data-automation="postStepDelay"
        placeholder="{{ 'asset.create.fire_order.post_step_delay' | translate }}"
      />
    </mat-form-field>
    <app-fire-order-step-check-form [step]="step"></app-fire-order-step-check-form>
  </div>
</div>
