<section class="step-section" cdkDrag cdkDragLockAxis="y" [formGroup]="step" *ngIf="step" data-automation="step-section">
  <mat-expansion-panel
    (opened)="stepOpenState = true"
    (closed)="stepOpenState = false"
    hideToggle="true"
    class="step-panel"
  >
    <mat-expansion-panel-header
      collapsedHeight="132px"
      expandedHeight="52px"
      [ngClass]="{ 'expanded' : stepOpenState }"
    >
      <mat-panel-title>
        <span class="before-actions-wrapper">
          <mat-icon cdkDragHandle class="drag-handle" data-automation="drag-handle" title="{{'asset.create.fire_order.drag_step' | translate }}">drag_indicator</mat-icon>
          <mat-icon cdkDragHandle class="play-button">play_arrow</mat-icon>
        </span>
        <span class="step-controls" data-automation="step-label" title="{{ controlDisplayLabel }}">{{ controlDisplayLabel }}</span>
        <span class="after-actions-wrapper">
          <mat-icon (click)="handleStepAdd($event)" class="add-button" title="{{'asset.create.fire_order.add_step' | translate }}">add_circle</mat-icon>
          <mat-icon *ngIf="controls && controls.length === 0" (click)="handleStepDelete($event, index)" class="delete-button" title="{{'asset.create.fire_order.delete_step' | translate }}">delete</mat-icon>
        </span>
      </mat-panel-title>
      <mat-panel-description class="panel-description">
        <div class="row">
          <div class="step-field-wrapper">
            <span class="row-header">{{ 'asset.create.fire_order.control_retries' | translate }}: <span class="count">{{ controlRetries || 0 }}</span></span>
          </div>
          <div class="step-field-wrapper">
            <span class="row-header">{{ 'asset.create.fire_order.control_retry_delay' | translate }}: <span class="count">{{ controlRetryDelay || 0 }}</span></span>
          </div>
          <div class="step-field-wrapper">
            <span class="row-header">{{ 'asset.create.fire_order.precheck_delay' | translate }}: <span class="count">{{ preCheckDelay || 0 }}</span></span>
          </div>
          <div class="step-field-wrapper">
            <span class="row-header">{{ 'asset.create.fire_order.check_retries' | translate }}: <span class="count">{{ checkRetries || 0 }}</span></span>
          </div>
          <div class="step-field-wrapper">
            <span class="row-header">{{ 'asset.create.fire_order.check_retry_delay' | translate }}: <span class="count">{{ checkRetryDelay || 0 }}</span></span>
          </div>
          <div class="step-field-wrapper">
            <span class="row-header">{{ 'asset.create.fire_order.checks.title' | translate }}: <span class="count">{{ checks ? checks.length : 0 }}</span></span>
          </div>
          <div class="step-field-wrapper full-width">
            <span class="row-header">{{ 'asset.create.fire_order.post_step_delay' | translate }}: <span class="count">{{ postStepDelay || 0 }}</span></span>
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-fire-order-step-form [step]="step"></app-fire-order-step-form>
  </mat-expansion-panel>
</section>
