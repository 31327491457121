import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AssetDetailsService } from '../../asset-details/asset-details.service';
import { ControlContainer, UntypedFormArray, UntypedFormGroup, NgForm } from '@angular/forms';
import { CookieService as NgxCookieService } from 'ngx-shared-services';

@Component({
  selector: 'app-fire-order-step',
  templateUrl: './fire-order-step.component.html',
  styleUrls: ['./fire-order-step.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FireOrderStepComponent implements OnInit, AfterViewInit {
  stepOpenState = false;
  controlElements: any[];
  points: any[];
  constructor(private assetDetailsService: AssetDetailsService, private cookieService: NgxCookieService) {}

  @Input() step: UntypedFormGroup;
  @Input() index: number;

  get controls() {
    return this.step.get('controls').value;
  }

  get controlRetries() {
    return this.step.get('controlRetries').value;
  }

  get controlRetryDelay() {
    return this.step.get('controlRetryDelay').value;
  }

  get checkRetries() {
    return this.step.get('checkRetries').value;
  }

  get checkRetryDelay() {
    return this.step.get('checkRetryDelay').value;
  }

  get postStepDelay() {
    return this.step.get('postStepDelay').value;
  }

  get preCheckDelay() {
    return this.step.get('preCheckDelay').value;
  }

  get checks() {
    return (this.step.get('checks') || []) as UntypedFormArray;
  }

  get controlDisplayLabel() {
    let stepControlsLabel = '';
    if (this.step && this.controlElements && this.controlElements.length > 0) {
      const controlIds = this.step.get('controls').value;
      if (controlIds && controlIds.length > 0) {
        this.controlElements.filter((controlElement) => {
          if (controlIds.includes(controlElement.id)) {
            stepControlsLabel = stepControlsLabel
              ? `${stepControlsLabel}, ${controlElement.displayLabel}`
              : controlElement.displayLabel;
          }
        });
      }
    }

    return stepControlsLabel || '<Control Elements>';
  }

  ngOnInit(): void {
    this.assetDetailsService.selectedControlElements$.subscribe((controlElements) => {
      this.controlElements = controlElements;
    });
  }

  ngAfterViewInit(): void {}

  handleStepDelete($event, index) {
    $event.stopPropagation();
    this.assetDetailsService.removeFireOrderStep({ index });
  }

  handleStepAdd($event) {
    $event.stopPropagation();
    this.assetDetailsService.addFireOrderStep({});
  }
}
