import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { Asset, Equipment, FireOrderOptions } from '../shared/model/asset.model';
import { ApiService } from '../shared/services/api.service';
import { Site } from '../shared/model/space.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { AssetsService } from '../shared/services/assets.service';

@Injectable()
export class AssetDetailsService {
  loadingSites = false;
  loadingEquipment = false;
  readonly defaultEquipment: Equipment = {
    METER: [],
    CONTROL_SET: [],
    MACHINERY: [],
  };
  readonly sites$: BehaviorSubject<Site[]>;
  readonly asset$ = new Subject<Asset>();
  readonly selectedSiteId$ = new BehaviorSubject<string>(null);
  readonly equipment$ = new BehaviorSubject<Equipment>(this.defaultEquipment);
  readonly selectedAsset = new BehaviorSubject<string>('');
  readonly addFireOrderStep$ = new Subject();
  readonly removeFireOrderStep$ = new Subject();
  readonly selectedControlElements$ = new BehaviorSubject<any[]>([]);
  readonly siteSpaces$ = new BehaviorSubject<any[]>([]);

  constructor(private apiService: ApiService, private assetService: AssetsService) {
    this.sites$ = new BehaviorSubject<Site[]>([]);
    this.selectedSiteId$.subscribe((siteId: string) => {
      if (siteId) {
        this.getEquipmentForSite(siteId);
      }
    });
  }

  selectSite(siteId: string): void {
    this.selectedSiteId$.next(siteId);
  }

  setAsset(asset: any): void {
    this.asset$.next(asset);
  }

  setEquipment(equipment: any): void {
    this.equipment$.next(equipment);
  }

  setSiteSpaces(fireOrderCheckSpaces: any) {
    const sanitizedSpaces: any = {};
    for (let key of Object.keys(fireOrderCheckSpaces)) {
      sanitizedSpaces[key] = fireOrderCheckSpaces[key].map((space) => {
        return convertKeys.toCamel(space);
      });
    }
    this.siteSpaces$.next(sanitizedSpaces);
  }

  setSelectedControlElements(controlElements: any) {
    this.selectedControlElements$.next(controlElements);
  }

  clearSiteSpaces() {
    this.siteSpaces$.next([]);
  }

  async getAssetDetails(assetId) {
    const asset = await this.assetService.getAsset(assetId);
    if (asset) {
      this.setAsset(asset);
    }
  }

  async getSitesForOrg(orgId: string) {
    try {
      this.loadingSites = true;
      const sites = await this.apiService.get(`sites/${orgId}`);
      this.sites$.next(sites.map(convertKeys.toCamel));
      this.loadingSites = false;
    } catch (err) {
      console.log('Could not load Sites.', err);
      this.loadingSites = false;
      this.sites$.next([]);
    }
  }

  async getEquipmentForSite(siteId: string) {
    try {
      this.loadingEquipment = true;
      const equipmentResponse = await this.apiService.get(`equipment/?site_id=${siteId}`);
      const { equipment, fireOrderCheckSpaces } = equipmentResponse;
      let sanitizedEquipment = {};
      Object.keys(equipment).forEach((equipmentType) => {
        sanitizedEquipment[equipmentType] = convertKeys.toCamel(equipment[equipmentType]);
      });
      if (sanitizedEquipment === {}) {
        sanitizedEquipment = this.defaultEquipment;
      }
      // @ts-ignore
      this.setEquipment(sanitizedEquipment);
      this.setSiteSpaces(fireOrderCheckSpaces);
      this.loadingEquipment = false;
    } catch (err) {
      console.log('Could not load Equipment.', err);
      this.loadingEquipment = false;
      this.setEquipment({});
    }
  }

  addFireOrderStep(fireOrderOptions: FireOrderOptions) {
    this.addFireOrderStep$.next(fireOrderOptions);
  }

  removeFireOrderStep(fireOrderOptions: FireOrderOptions) {
    this.removeFireOrderStep$.next(fireOrderOptions);
  }
}
