import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Asset } from '../shared/model/asset.model';
import { AssetsService } from '../shared/services/assets.service';
import { AssetDetailsService } from '../asset-details/asset-details.service';
import { Locale } from '../shared/model/locale.model';
import { Timezone } from '../shared/model/timezone.model';
import { LocalesService } from '../shared/services/locales.service';
import { TimezonesService } from '../shared/services/timezones.service';
import { DeleteDialogComponent } from '../dialogs/delete/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DeeplinksService } from '../shared/services/deeplinks.service';
import { OrganizationsService } from '../shared/services/organizations.service';
import { Status } from '../shared/model/status.model';
import { StatusesService } from '../shared/services/statuses.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss', '../shared/shared.styles.scss'],
})
export class ViewComponent implements OnInit, OnDestroy {
  equipmentAdminURL: string;

  assetId = '';
  assetOrgId: string;
  appData: any = {};
  subscriptions = [];
  locales: Locale[];
  timezones: Timezone[];
  statuses: Status[];
  loadingAsset = false;
  loadingEquipment = false;
  asset: Asset = {
    id: '',
    displayLabel: '',
    site: {
      displayLabel: '-',
    },
    defaultLocale: '',
    timezone: '',
    status: '',
    monitors: [],
    performance: [],
    submeters: [],
    commands: [],
    includes: [],
    description: '',
    displayLabels: {},
  };
  assetMonitors: any[];
  assetPerformance: any[];
  assetSubmeters: any[];
  assetCommands: any[];
  assetIncludes: any[];

  constructor(
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private assetService: AssetsService,
    private assetDetailsService: AssetDetailsService,
    private organizationsService: OrganizationsService,
    private deeplinksInternalService: DeeplinksService,
    private statusesService: StatusesService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) {
    this.timezones = timezonesService.timezones;
    this.statuses = statusesService.statuses;
    this.localesService.locales$.subscribe((locales) => {
      this.locales = locales;
    });
    this.deeplinksInternalService.setEquipmentAdminUrl();
    this.deeplinksInternalService.deeplink$.subscribe(async (deeplinkUrl) => {
      this.equipmentAdminURL = deeplinkUrl;
    });
    this.loadDynamicLists();
  }

  ngOnInit() {
    const routeSub = this.route.params.subscribe(async (params) => {
      if (params.id) {
        this.assetId = params.id;
        this.loadingAsset = true;
        this.assetDetailsService.selectedAsset.next(this.assetId);
        await this.assetDetailsService.getAssetDetails(params.id);
        if (this.organizationsService.isInit) {
          this.assetOrgId = this.asset.site.orgId || (await this.assetService.getOrgForSite(this.asset.site.id)).id;
          this.organizationsService.getOrgs(this.assetOrgId);
        }
        this.loadingAsset = false;
      }
    });

    const assetSub = this.assetDetailsService.asset$.subscribe(async (asset) => {
      console.log('GET Asset', asset);
      if (asset) {
        this.asset = asset;
        if (this.asset.site.id) {
          this.loadingEquipment = true;
          await this.assetDetailsService.getEquipmentForSite(this.asset.site.id);
          this.loadingEquipment = false;
        }
      }
    });

    const equipmentSub = this.assetDetailsService.equipment$.subscribe((equipmentData) => {
      if (equipmentData) {
        this.setEquipmentList(equipmentData);
      }
    });
    this.subscriptions = [routeSub, assetSub, equipmentSub];
    this.statusesService.setStatuses();
  }

  setEquipmentList(equipmentData) {
    this.assetIncludes = [];
    if (equipmentData.METER) {
      if (this.asset.monitors && this.asset.monitors.length > 0) {
        this.assetMonitors = equipmentData.METER.map((meter) => {
          const meterClone = { ...meter };
          if (meter.children) {
            meterClone.children = meter.children.filter((point) => this.asset.monitors.includes(point.id));
          }
          return meterClone;
        });
      } else {
        this.assetMonitors = [];
      }
      if (this.asset.performance && this.asset.performance.length > 0) {
        this.assetPerformance = equipmentData.METER.map((meter) => {
          const meterClone = { ...meter };
          if (meter.children) {
            meterClone.children = meter.children.filter((point) => this.asset.performance.includes(point.id));
          }
          return meterClone;
        });
      } else {
        this.assetPerformance = [];
      }
      if (this.asset.submeters && this.asset.submeters.length > 0) {
        this.assetSubmeters = equipmentData.METER.map((meter) => {
          const meterClone = { ...meter };
          if (meter.children) {
            meterClone.children = meter.children.filter((point) => this.asset.submeters.includes(point.id));
          }
          return meterClone;
        });
      } else {
        this.assetSubmeters = [];
      }
    }

    if (this.asset.commands && this.asset.commands.length > 0 && equipmentData.CONTROL_SET) {
      let controlElements: any[] = [];

      this.assetCommands = equipmentData.CONTROL_SET.map((controlSet) => {
        const controlSetClone = { ...controlSet };
        if (controlSet.children) {
          controlSetClone.children = controlSet.children.filter((point) => this.asset.commands.includes(point.id));
          controlElements = [...controlElements, ...controlSetClone.children];
        }
        return controlSetClone;
      });
      this.assetDetailsService.setSelectedControlElements(controlElements);
    } else {
      this.assetCommands = [];
      this.assetDetailsService.setSelectedControlElements([]);
    }
    if (this.asset.includes && this.asset.includes.length > 0) {
      const equipmentTypes = ['MACHINERY', 'BESS', 'GENERATOR'];
      equipmentTypes.forEach((equipmentType) => {
        if (equipmentData[equipmentType]) {
          this.assetIncludes = this.assetIncludes.concat(
            equipmentData[equipmentType].filter((equipment) => this.asset.includes.includes(equipment.id)),
          );
        }
      });
    } else {
      this.assetIncludes = [];
    }
  }

  getStatusForPortfolio() {
    if (this.statuses && this.statuses.length > 0) {
      return (
        this.statuses.find((status) => status.code === this.asset.status)?.displayLabel || new Status().displayLabel
      );
    }

    return '-';
  }

  getSiteDisplayLabel(): string {
    if (this.asset && this.asset.site && typeof this.asset.site === 'object') {
      return this.asset.site.displayLabel;
    }
    return '-';
  }

  getLocaleDisplayLabel(): string {
    if (this.locales && this.locales.length > 0 && this.asset && this.asset.defaultLocale) {
      return this.locales.find((locale) => locale.localeName === this.asset.defaultLocale).displayLabel;
    }
    return '-';
  }

  getTimezoneDisplayLabel(): string {
    if (this.timezones.length > 0 && this.asset && this.asset.timezone) {
      return this.timezones.find((timezone) => timezone.timezoneName === this.asset.timezone).displayLabel;
    }
    return '-';
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  handleCancel() {
    this.router.navigate(['/']);
  }

  openDeleteDialog(): void {
    this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {
        assetId: this.assetId,
      },
    });
  }

  getEquipmentAdminUrl(equipmentId, page) {
    if (this.equipmentAdminURL) {
      return `${this.equipmentAdminURL}/details/${equipmentId}/${page}`;
    }
  }

  handleEdit() {
    this.router.navigate([`details/${this.assetId}/edit`]);
  }

  private async loadDynamicLists() {
    this.localesService.setLocales();
    this.timezonesService.setTimezones();
  }
}
