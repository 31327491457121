<div class="app-asset-edit content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon>edit</mat-icon>
                </div>
                <h1 id="create-title" data-automation="createTitle">{{ 'asset.edit.page_title' | translate }}</h1>
                <p class="subtitle" data-automation="subtitle">{{ 'asset.edit.page_subtitle' | translate }}</p>
            </div>
        </div>

        <div class="right-column">
            <button
                class="icon-btn x-btn-secondary"
                color="gray"
                aria-label="Edit"
                (click)="handleCancel()"
                [disabled]="this.assetId === ''"
                data-automation="cancel"
            >
                <mat-icon>clear</mat-icon>
                <span class="btn-text"> {{ 'asset.edit.button.cancel' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                color="accent"
                aria-label="Delete"
                (click)="openDeleteDialog()"
                [disabled]="this.assetId === ''"
                data-automation="delete"
            >
                <mat-icon>delete</mat-icon>
                <span class="btn-text"> {{ 'asset.edit.button.delete' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                color="accent"
                aria-label="Edit"
                (click)="handleSubmit()"
                [disabled]="!assetForm.valid"
                data-automation="submit"
            >
                <mat-icon *ngIf="!isSubmitting">done</mat-icon>
                <div *ngIf="isSubmitting" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text"> {{ 'asset.edit.button.save' | translate }}</span>
            </button>
        </div>
    </header>

    <form #edit="ngForm" class="asset-form">
        <app-asset
            [mode]="mode"
            [assetForm]="assetForm"
            [assetOrgId]="assetOrgId"
            [currentSite]="currentSite"
        ></app-asset>
    </form>
</div>
