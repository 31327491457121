import { Component, Input, OnInit } from '@angular/core';
import { AssetDetailsService } from '../../asset-details/asset-details.service';
import { ControlContainer, UntypedFormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-fire-order-step-form',
  templateUrl: './fire-order-step-form.component.html',
  styleUrls: ['./fire-order-step-form.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FireOrderStepFormComponent implements OnInit {
  controlElements: any[];
  dropDownValues: number[] = [0, 1, 2, 3, 4, 5];
  constructor(private assetDetailsService: AssetDetailsService) {}

  @Input() step: UntypedFormGroup;

  get controls() {
    return this.step.get('controls');
  }

  ngOnInit(): void {
    this.assetDetailsService.selectedControlElements$.subscribe((controlElements) => {
      this.controlElements = controlElements;
    });
  }
}
