<div class="app-asset-view content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon *ngIf="!loadingAsset">emoji_objects</mat-icon>
                    <mat-spinner *ngIf="loadingAsset" matSuffix diameter="30" color="warn"></mat-spinner>
                </div>
                <h1 id="view-title" data-automation="name">
                    <ng-container *ngIf="!loadingAsset">{{ asset.displayLabel }}</ng-container>
                    <ng-container *ngIf="loadingAsset">{{ 'asset.view.loading_fa' | translate }}</ng-container>
                </h1>
                <p class="subtitle" data-automation="subtitle">{{ 'asset.view.page_subtitle' | translate }}</p>
            </div>
        </div>
        <div class="right-column">
            <button
                class="icon-btn x-btn-primary"
                color="accent"
                aria-label="delete"
                (click)="openDeleteDialog()"
                [disabled]="this.assetId === ''"
                data-automation="delete"
            >
                <mat-icon>delete</mat-icon>
                <span class="btn-text"> {{ 'asset.view.button.delete' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                color="accent"
                aria-label="Edit"
                (click)="handleEdit()"
                data-automation="edit"
            >
                <mat-icon>edit</mat-icon>
                <span class="btn-text"> {{ 'asset.view.button.edit' | translate }}</span>
            </button>
        </div>
    </header>

    <form #view="ngForm" class="asset-form">
        <div class="form-group">
            <div class="view-field-container small">
                <mat-label class="field-label">{{ 'asset.create.status' | translate }}:</mat-label>
                <div class="field-value" data-automation="status">
                    {{ getStatusForPortfolio() | translate }}
                </div>
            </div>
            <div class="view-field-container">
                <mat-label class="field-label">{{ 'asset.create.site' | translate }}:</mat-label>
                <div class="field-value" data-automation="site" matTooltip="{{ getSiteDisplayLabel() }}" matTooltipPosition="above">
                    {{ getSiteDisplayLabel() }}
                </div>
            </div>
            <div class="view-field-container small">
                <mat-label class="field-label">{{ 'asset.create.default_locale' | translate }}:</mat-label>
                <div class="field-value" data-automation="locale">
                    {{ getLocaleDisplayLabel() }}
                </div>
            </div>
            <div class="view-field-container">
                <mat-label class="field-label">{{ 'asset.create.timezone' | translate }}:</mat-label>
                <div class="field-value" data-automation="timezone">
                    {{ getTimezoneDisplayLabel() }}
                </div>
            </div>
        </div>

        <h4 class="form-header">{{ 'asset.create.monitors' | translate }}</h4>
        <div class="form-group">
            <div class="view-field-container">
                <div class="field-value" data-automation="monitors">
                    <div *ngFor="let meter of assetMonitors">
                        <div *ngIf="meter.children && meter.children.length > 0">
                            <div class="list-parent ellipsis">
                                <a
                                    href="{{ getEquipmentAdminUrl(meter.id, 'view') }}"
                                    target="_blank"
                                    class="new-tab-link"
                                >
                                    {{ meter.displayLabel }}
                                    <mat-icon>open_in_new</mat-icon>
                                </a>
                            </div>
                            <ul class="list-children">
                                <li
                                    *ngFor="let measuredPoint of meter.children"
                                    class="ellipsis"
                                    data-automation="monitor-label"
                                >
                                    {{ measuredPoint.displayLabel }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <h4 class="form-header">{{ 'asset.create.performance' | translate }}</h4>
        <div class="form-group">
            <div class="view-field-container">
                <div class="field-value" data-automation="performance">
                    <div *ngFor="let meter of assetPerformance">
                        <div *ngIf="meter.children && meter.children.length > 0">
                            <div class="list-parent ellipsis">
                                <a
                                    href="{{ getEquipmentAdminUrl(meter.id, 'view') }}"
                                    target="_blank"
                                    class="new-tab-link"
                                >
                                    {{ meter.displayLabel }}
                                    <mat-icon>open_in_new</mat-icon>
                                </a>
                            </div>
                            <ul class="list-children">
                                <li
                                    *ngFor="let measuredPoint of meter.children"
                                    class="ellipsis"
                                    data-automation="performance-label"
                                >
                                    {{ measuredPoint.displayLabel }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <h4 class="form-header">{{ 'asset.create.submeters' | translate }}</h4>
        <div class="form-group">
            <div class="view-field-container">
                <div class="field-value" data-automation="submeters">
                    <div *ngFor="let meter of assetSubmeters">
                        <div *ngIf="meter.children && meter.children.length > 0">
                            <div class="list-parent ellipsis">
                                <a
                                    href="{{ getEquipmentAdminUrl(meter.id, 'view') }}"
                                    target="_blank"
                                    class="new-tab-link"
                                >
                                    {{ meter.displayLabel }}
                                    <mat-icon>open_in_new</mat-icon>
                                </a>
                            </div>
                            <ul class="list-children">
                                <li
                                    *ngFor="let measuredPoint of meter.children"
                                    class="ellipsis"
                                    data-automation="submeters-label"
                                >
                                    {{ measuredPoint.displayLabel }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <h4 class="form-header">{{ 'asset.create.commands' | translate }}</h4>
        <div class="form-group">
            <div class="view-field-container">
                <div class="field-value" data-automation="commands">
                    <div *ngFor="let controlSet of assetCommands">
                        <div *ngIf="controlSet.children && controlSet.children.length > 0">
                            <div class="list-parent ellipsis">
                                <a
                                    href="{{ getEquipmentAdminUrl(controlSet.id, 'view') }}"
                                    target="_blank"
                                    class="new-tab-link"
                                >
                                    {{ controlSet.displayLabel }}
                                    <mat-icon>open_in_new</mat-icon>
                                </a>
                            </div>
                            <ul class="list-children">
                                <li
                                    *ngFor="let controlElement of controlSet.children"
                                    class="ellipsis"
                                    data-automation="command-label"
                                >
                                    {{ controlElement.displayLabel }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <h4 class="form-header">{{ 'asset.create.includes' | translate }}</h4>
        <div class="form-group">
            <div class="view-field-container">
                <div class="field-value" data-automation="includes">
                    <ul *ngIf="assetIncludes && assetIncludes.length > 0">
                        <li *ngFor="let equipment of assetIncludes" class="ellipsis" data-automation="include-label">
                            <div class="list-parent">
                                <a
                                    href="{{ getEquipmentAdminUrl(equipment.id, 'view') }}"
                                    target="_blank"
                                    class="new-tab-link"
                                >
                                    {{ equipment.displayLabel }}
                                    <mat-icon>open_in_new</mat-icon>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <h4 class="form-header">{{ 'asset.create.fire_order.title' | translate }}</h4>
        <div class="fire-order-section">
            <app-fire-order-view [asset]="asset"></app-fire-order-view>
        </div>
    </form>
</div>
