<section class="step-section" *ngIf="step">
  <mat-expansion-panel
    (opened)="stepOpenState = true"
    (closed)="stepOpenState = false"
    hideToggle="true"
    class="step-panel"
    expanded="true"
  >
    <mat-expansion-panel-header
      collapsedHeight="50px"
      expandedHeight="50px"
      [ngClass]="{ 'expanded' : stepOpenState }"
    >
      <mat-panel-title>
        <span class="before-actions-wrapper">
          <mat-icon cdkDragHandle class="play-button">play_arrow</mat-icon>
        </span>
        <span class="step-controls"  data-automation="controls" title="{{ controlDisplayLabel }}">{{ controlDisplayLabel }}</span>
      </mat-panel-title>
      <mat-panel-description class="panel-description"></mat-panel-description>
    </mat-expansion-panel-header>
    <div class="panel-description-view">
      <div class="row">
        <div class="step-field-wrapper">
          <span class="row-header" data-automation="controlRetries">{{ 'asset.create.fire_order.control_retries' | translate }}: <span class="count">{{ controlRetries || 0 }}</span></span>
        </div>
        <div class="step-field-wrapper">
          <span class="row-header" data-automation="controlRetryDelay">{{ 'asset.create.fire_order.control_retry_delay' | translate }}: <span class="count">{{ controlRetryDelay || 0 }}</span></span>
        </div>
        <div class="step-field-wrapper">
          <span class="row-header" data-automation="preCheckDelay">{{ 'asset.create.fire_order.precheck_delay' | translate }}: <span class="count">{{ preCheckDelay || 0 }}</span></span>
        </div>
        <div class="step-field-wrapper">
          <span class="row-header" data-automation="checkRetries">{{ 'asset.create.fire_order.check_retries' | translate }}: <span class="count">{{ checkRetries || 0 }}</span></span>
        </div>
        <div class="step-field-wrapper">
          <span class="row-header" data-automation="checkRetryDelay">{{ 'asset.create.fire_order.check_retry_delay' | translate }}: <span class="count">{{ checkRetryDelay || 0 }}</span></span>
        </div>
        <div class="step-field-wrapper">
          <span class="row-header" data-automation="checks">{{ 'asset.create.fire_order.checks.title' | translate }}: <span class="count">{{ checks ? checks.length : 0 }}</span></span>
        </div>
        <div class="step-field-wrapper full-width">
          <span class="row-header" data-automation="postStepDelay">{{ 'asset.create.fire_order.post_step_delay' | translate }}: <span class="count">{{ postStepDelay || 0 }}</span></span>
        </div>
      </div>
    </div>
    <div class="checks-wrapper" *ngIf="checks && checks.length">
      <mat-label class="check-label" *ngIf="checks.length > 0">{{ 'asset.create.fire_order.checks.title' | translate }}</mat-label>
      <div class="check-wrapper">
        <div *ngFor="let check of checks; let checkIndex = index">
          <div class="fire-order-group check-group">
            <div class="check-field-wrapper full-width">
              <mat-label>{{ 'asset.create.fire_order.checks.space_id' | translate }}:</mat-label>
              <div class="check-value" data-automation="space">{{ getSpace(check) }}</div>
            </div>
            <div class="check-field-wrapper">
              <mat-label>{{ 'asset.create.fire_order.checks.type' | translate }}:</mat-label>
              <div class="check-value" data-automation="checkType">{{ getCheckType(check) }}</div>
            </div>
            <div class="check-field-wrapper">
              <mat-label>{{ 'asset.create.fire_order.checks.channel' | translate }}:</mat-label>
              <div class="check-value" data-automation="channel">{{ getChannel(check) }}</div>
            </div>
            <div class="check-field-wrapper">
              <mat-label>{{ 'asset.create.fire_order.checks.period' | translate }}:</mat-label>
              <div class="check-value" data-automation="granularity">{{  getPeriod(check) }}</div>
            </div>
            <div class="check-field-wrapper">
              <mat-label>{{ 'asset.create.fire_order.checks.operator' | translate }}:</mat-label>
              <div class="check-value" data-automation="operator">{{ getOperator(check) }}</div>
            </div>
            <div class="check-field-wrapper">
              <mat-label>{{ 'asset.create.fire_order.checks.threshold' | translate }}:</mat-label>
              <div class="check-value" data-automation="threshold">{{ check.threshold }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</section>
