import { Component } from '@angular/core';
import { Form, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { AssetsService } from '../shared/services/assets.service';
import { AssetDetailsService } from '../asset-details/asset-details.service';
import { OrganizationsService } from '../shared/services/organizations.service';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { Locale } from '../shared/model/locale.model';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss', '../shared/shared.styles.scss'],
})
export class CreateComponent {
  SUCCESS = 'Created Successfully';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  NOT_CREATED = 'Oops, There was a problem creating your asset';
  REQUIRED = 'required';

  isSubmitting = false;

  assetForm: FormGroup = new FormGroup({
    displayLabels: new FormControl({}, Validators.required),
    supportedLocales: new FormControl([new Locale()], Validators.required),
    site: new FormControl(null, Validators.required),
    defaultLocale: new FormControl('en_US', Validators.required),
    timezone: new FormControl('America/New_York', Validators.required),
    status: new FormControl('ACTIVE', Validators.required),
    monitors: new FormControl([], Validators.minLength(1)),
    performance: new FormControl([], Validators.minLength(1)),
    submeters: new FormControl([], Validators.minLength(1)),
    commands: new FormControl([]),
    includes: new FormControl([]),
    fireOrder: new FormGroup({
      curtail: new FormArray([]),
      restore: new FormArray([]),
    }),
  });
  readonly mode = 'create';
  readonly APPPREFIX = 'ast';

  constructor(
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private router: Router,
    private messageService: GlobalAlertService,
    private assetsService: AssetsService,
    private assetDetailService: AssetDetailsService,
    private organizationsService: OrganizationsService,
    private translateService: TranslateService,
  ) {
    if (this.organizationsService.isInit) {
      this.organizationsService.getOrgs();
    }
    this.assetDetailService.selectedAsset.next('');
    this.translateService.get('asset.notification.created_successfully').subscribe((result: string) => {
      this.SUCCESS = result;
      this.BAD_REQUEST = this.translateService.instant('asset.notification.bad_request');
      this.NOT_CREATED = this.translateService.instant('asset.notification.not_created');
      this.REQUIRED = this.translateService.instant('asset.validation.required');
    });
  }

  get fireOrder() {
    return this.assetForm.get('fireOrder');
  }

  get curtail() {
    return (this.fireOrder.get('curtail') || []) as FormArray;
  }

  get restore() {
    return (this.fireOrder.get('restore') || []) as FormArray;
  }

  handleCancel() {
    this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX });
    this.router.navigate(['/']);
  }

  async handleSubmit() {
    console.log('POST', this.assetForm.getRawValue());
    if (!this.assetForm.valid) {
      this.messageService.setError(this.REQUIRED);
    } else {
      try {
        this.isSubmitting = true;
        if (this.curtail.length === 0 && this.restore.length === 0) {
          this.assetForm.removeControl('fireOrder');
        }
        const response = await this.assetsService.createAsset({ ...this.assetForm.getRawValue() });
        this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX, callbackValue: response.id });
        this.messageService.setSuccess(this.SUCCESS);
        this.isSubmitting = false;
        setTimeout(() => {
          this.assetsService.refetchAssets();
          this.router.navigate([`details/${response.id}/view`], {});
        }, 2000);
      } catch (e) {
        this.isSubmitting = false;
        console.log(e);
        const errorMessage = e.error?.message;
        if (errorMessage === 'ERR_BAD_REQUEST') {
          this.messageService.setError(this.BAD_REQUEST);
        } else {
          this.messageService.setError(this.NOT_CREATED);
        }
      }
    }
  }
}
