/* * * ANGULAR * * */
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from "./material.module";

/* * * 3RD PARTY * * */
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/* * * SHARED * * */
import { GlobalFooterModule } from 'ngx-global-contact-footer';
import { GlobalNavModule } from 'ngx-global-nav';

import { NgxDeeplinkerModule } from 'ngx-deeplinker';
import { AnalyticsService, CookieService as NgxCookieService, NOC_ANALYTICS } from 'ngx-shared-services';
import { analytics } from 'noc-analytics';
import { CookieService } from './shared/services/cookie.service';
import { GlobalAlertService } from './shared/services/global-alert.service';
import { GlobalAlertModule } from './global-alert/global-alert.module';
import { I18nService } from './shared/services/i18n.service';
import { AssetsService } from './shared/services/assets.service';
import { AssetDetailsService } from './asset-details/asset-details.service';
import { RefService } from './shared/services/ref.service';

import { AuthHttpInterceptor } from './services/auth.interceptor';
import { MockHttpInterceptor } from './services/mock.interceptor';
import { SelectValidator } from './shared/directives/select-valid.directive';

/* * * COMPONENTS * * */
import { AppRoutingModule } from './app-routing.module';
import { MultiLocaleModule } from 'multi-locale-input';
import { AppComponent } from './app.component';
import { CreateComponent } from './create/create.component';
import { DefaultComponent } from './default/default.component';
import { ListComponent } from './list/list.component';
import { EditComponent } from './edit/edit.component';
import { ViewComponent } from './view/view.component';
import { AssetDetailsComponent } from './asset-details/asset-details.component';
import { DeleteDialogComponent } from './dialogs/delete/delete-dialog.component';

import { FireOrderComponent } from './fire-order/fire-order.component';
import { FireOrderStepComponent } from './fire-order/fire-order-step/fire-order-step.component';
import { FireOrderStepFormComponent } from './fire-order/fire-order-step-form/fire-order-step-form.component';
import { FireOrderStepCheckFormComponent } from './fire-order/fire-order-step-check-form/fire-order-step-check-form.component';
import { FireOrderViewComponent } from './fire-order/fire-order-view/fire-order-view.component';
import { FireOrderViewStepComponent } from './fire-order/fire-order-view-step/fire-order-view-step.component';

import { ApiService } from './shared/services/api.service';
import { DeeplinksService } from './shared/services/deeplinks.service';
import { LocalesService } from './shared/services/locales.service';
import { TimezonesService } from './shared/services/timezones.service';
import { SidenavService } from './shared/services/sidenav.service';
import { OrganizationsService } from './shared/services/organizations.service';
import { StatusesService } from './shared/services/statuses.service';
import { NavService } from './shared/services/global-nav.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DefaultComponent,
    ListComponent,
    CreateComponent,
    EditComponent,
    AssetDetailsComponent,
    ViewComponent,
    SelectValidator,
    DeleteDialogComponent,
    FireOrderComponent,
    FireOrderStepComponent,
    FireOrderStepFormComponent,
    FireOrderStepCheckFormComponent,
    FireOrderViewComponent,
    FireOrderViewStepComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    GlobalNavModule.forRoot({ namespace: 'ngx-global-nav.' }),
    GlobalFooterModule.forRoot({ namespace: 'ngx-global-contact-footer.' }),
    GlobalAlertModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot([]),
    AppRoutingModule,
    MaterialModule,
    NgxDeeplinkerModule,
    ReactiveFormsModule,
    MultiLocaleModule,
  ],
  providers: [
    TranslateService,
    AnalyticsService,
    NgxCookieService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: MockHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    HttpClient,
    ApiService,
    GlobalAlertService,
    I18nService,
    AssetsService,
    AssetDetailsService,
    OrganizationsService,
    TimezonesService,
    LocalesService,
    DeeplinksService,
    SidenavService,
    RefService,
    StatusesService,
    NavService,
    { provide: NOC_ANALYTICS, useValue: analytics },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
