import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormArray, UntypedFormGroup, NgForm } from '@angular/forms';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { Asset } from '../shared/model/asset.model';
import { LocalesService } from '../shared/services/locales.service';
import { TimezonesService } from '../shared/services/timezones.service';
import { Timezone } from '../shared/model/timezone.model';
import { Locale } from '../shared/model/locale.model';
import { AssetDetailsService } from './asset-details.service';
import { DeeplinksService } from '../shared/services/deeplinks.service';
import { Site } from '../shared/model/space.model';
import { throttleTime } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Status } from '../shared/model/status.model';
import { StatusesService } from '../shared/services/statuses.service';

@Component({
  selector: 'app-asset',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AssetDetailsComponent implements OnInit, AfterViewInit {
  readonly EDIT = 'edit';
  readonly CREATE = 'create';

  @ViewChild('monitorSelector', { static: true }) monitorSelector;

  orgId = '';
  initialEdit;
  appPrefix = 'ast';
  deeplinkerData: any;
  deeplinkerMeterConfig: any = {};
  deeplinkerControlSetConfig: any = {};
  deeplinkerMachineConfig: any = {};
  sites: Site[] = [];
  controlSetList: any[] = [];
  meterList: any[] = [];
  equipmentList: any[] = [];
  allEquipment: any[] = [];
  locales: Locale[];
  timezones: Timezone[];
  statuses: Status[];
  site_label_to_display: string = '';

  multiLocaleConfig = {
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
    defaultLocale: new Locale(),
  };

  namePlaceholder = '';
  nameLabel = '';
  userLocale = 'en_US';

  loadingSites;
  loadingEquipment;

  siteDependantFields = ['monitors', 'performance', 'submeters', 'commands', 'includes'];

  equipmentGroups: any[] = [
    {
      storedValues: 'meterList',
      equipmentTypes: ['METER'],
      formFields: ['monitors', 'performance', 'submeters'],
      hasChildren: true,
      disableIfEmpty: false,
    },
    {
      storedValues: 'controlSetList',
      equipmentTypes: ['CONTROL_SET'],
      formFields: ['commands'],
      hasChildren: true,
      disableIfEmpty: true,
    },
    {
      storedValues: 'equipmentList',
      equipmentTypes: ['MACHINERY', 'BESS', 'GENERATOR'],
      formFields: ['includes'],
      hasChildren: false,
      disableIfEmpty: true,
    },
  ];

  @Input() currentSite: any;
  @Input() assetForm: UntypedFormGroup;

  @Input()
  set assetOrgId(orgId: string) {
    if (orgId) {
      this.orgId = orgId;
      this.loadDynamicLists();
    }
  }

  @Input()
  set mode(mode: string) {
    this._mode = mode;
  }

  get mode() {
    return this._mode;
  }

  get asset() {
    return this._asset;
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get displayLabels() {
    return this.assetForm.get('displayLabels');
  }

  get displayLabel() {
    return this.assetForm.get('displayLabel');
  }

  get supportedLocales() {
    return this.assetForm.get('supportedLocales');
  }

  get site() {
    return this.assetForm.get('site');
  }

  get timezone() {
    return this.assetForm.get('timezone');
  }

  get locale() {
    return this.assetForm.get('defaultLocale');
  }

  get monitors() {
    return this.assetForm.get('monitors');
  }

  get performance() {
    return this.assetForm.get('performance');
  }

  get submeters() {
    return this.assetForm.get('submeters');
  }

  get commands() {
    return this.assetForm.get('commands');
  }

  get includes() {
    return this.assetForm.get('includes');
  }

  get defaultLocale() {
    return this.assetForm.get('defaultLocale');
  }

  get fireOrder() {
    return this.assetForm.get('fireOrder');
  }

  get curtail() {
    return this.fireOrder.get('curtail') as UntypedFormArray;
  }

  get restore() {
    return this.fireOrder.get('restore') as UntypedFormArray;
  }

  private _mode: string;
  private _asset: Asset;

  constructor(
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private orgSelctorService: ContextSelectorService,
    private assetDetailsService: AssetDetailsService,
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private deeplinksInternalService: DeeplinksService,
    private translateService: TranslateService,
    private ngxCookieService: NgxCookieService,
    private statusesService: StatusesService,
  ) {
    this.timezones = timezonesService.timezones;
    this.statuses = statusesService.statuses;
    this.deeplinkerMeterConfig = {
      appPrefix: this.appPrefix,
      idToPopulate: 'none',
      displayLabel: this.translateService.instant('asset.create.button.create_meter'),
      additionalQueryParams: {
        eqp: 'equipmentType=METER',
      },
    };
    this.deeplinkerControlSetConfig = {
      appPrefix: this.appPrefix,
      idToPopulate: 'none',
      displayLabel: this.translateService.instant('asset.create.button.create_control_set'),
      additionalQueryParams: {
        eqp: 'equipmentType=CONTROL_SET',
      },
    };
    this.deeplinkerMachineConfig = {
      appPrefix: this.appPrefix,
      idToPopulate: 'none',
      displayLabel: this.translateService.instant('asset.create.button.create_equipment'),
      additionalQueryParams: {
        eqp: 'equipmentType=MACHINERY',
      },
    };
    this.deeplinksInternalService.setEquipmentAdminUrl();
    this.deeplinksInternalService.deeplink$.subscribe(async (deeplinkUrl) => {
      this.deeplinkerMeterConfig.destinationUrl = deeplinkUrl + '/create';
      this.deeplinkerControlSetConfig.destinationUrl = deeplinkUrl + '/create';
      this.deeplinkerMachineConfig.destinationUrl = deeplinkUrl + '/create';
    });

    this.nameLabel = this.translateService.instant('asset.create.asset_name');
    this.namePlaceholder = this.translateService.instant('asset.create.placeholder.asset_name');
    this.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';
  }

  ngOnInit() {
    if (!this.isEditMode) {
      this.orgSelctorService.forceHideContextSelector(false);
      this.orgSelctorService.currentContext$.subscribe(async (orgs: Context[]) => {
        const org = orgs[0];
        this.orgId = org.id;
        this.loadDynamicLists();
      });
    }

    this.initialEdit = this.isEditMode;
    this.assetDetailsService.sites$.subscribe((sites) => {
      this.sites = sites || [];
      const deeplinkerData = this.ngxDeeplinkerService.getReturnData(this.appPrefix);
      if (deeplinkerData && this.isCreateMode) {
        if (Object.keys(deeplinkerData).length) {
          const {
            displayLabel,
            site,
            monitors,
            performance,
            submeters,
            commands,
            includes,
          } = deeplinkerData;
          this.displayLabels.setValue(displayLabel || '');
          this.site.setValue(site || '');
          this.monitors.setValue(monitors || '');
          this.performance.setValue(performance || '');
          this.submeters.setValue(submeters || '');
          this.commands.setValue(commands || '');
          this.includes.setValue(includes || '');
        }
      }
    });

    this.assetDetailsService.equipment$.subscribe((equipmentData) => {
      this.allEquipment = [];
      this.equipmentGroups.forEach((group: any) => {
        this[group.storedValues] = [];
        group.equipmentTypes.forEach((equipmentType) => {
          this[group.storedValues] = this[group.storedValues].concat(equipmentData[equipmentType] || []);
          group.formFields.forEach((formField: string) => {
            if (this[group.storedValues] && this[group.storedValues].length > 0) {
              this.assetForm.get(formField).enable();
              if (group.hasChildren) {
                const allMeasuredPoints: any[] = this[group.storedValues].reduce((allPoints, pointGroup) => {
                  if (pointGroup.children) {
                    return allPoints.concat(pointGroup.children);
                  }
                  return allPoints;
                }, []);
                this.allEquipment = this.allEquipment.concat(allMeasuredPoints);
              } else {
                this.allEquipment = this.allEquipment.concat(this[group.storedValues]);
              }
            } else {
              if (group.disableIfEmpty) {
                this.assetForm.get(formField).disable();
              }
            }
          });
        });
      });
      this.loadingEquipment = false;
    });

    this.localesService.locales$.subscribe((locales) => {
      this.locales = locales;
      const locale = this.localesService.checkForLocale(this.userLocale, locales) || locales[0];
      this.multiLocaleConfig.defaultLocale = locale;
      this.supportedLocales.patchValue([locale]);

      if (this.isCreateMode) {
        this.defaultLocale.patchValue(locale.localeName);
      }

      if (this.isEditMode) {
        this.buildSupportedLocales();
      }
    });

    this.assetDetailsService.asset$.subscribe(async (asset) => {
      if (asset) {
        this.displayLabels.setValue(asset.displayLabels);
        this.buildSupportedLocales();
      }
    });

    this.assetDetailsService.selectedSiteId$.subscribe((siteId: string) => {
      // user changed the site so default the timezone and locale
      if (siteId) {
        this.updateAssetLocaleAndTimezone(siteId);
      }
    });

    this.statusesService.setStatuses();

    this.assetForm.controls['displayLabels'].valueChanges.subscribe((value) => {
      console.log('values form changes', value);
    });
  }

  ngAfterViewInit() {
    // Measured Points selection logic (Monitor & Performance relationship)
    if (this.monitorSelector) {
      this.monitorSelector.optionSelectionChanges.pipe(throttleTime(100)).subscribe((change) => {
        const performanceValue = new Set(this.assetForm.get('performance').value);
        if (change.source.selected && change.isUserInput) {
          performanceValue.add(change.source.value);
        } else if (!change.source.selected) {
          performanceValue.delete(change.source.value);
        }
        this.assetForm.get('performance').setValue([...performanceValue]);
      });
    }

    this.commands.valueChanges.subscribe((value) => {
      if (!value || value.length === 0) {
        return;
      }

      const controlSetList = this.controlSetList.filter(
        (controlSet) => controlSet.children && controlSet.children.length > 0,
      );
      const controlElements = controlSetList.reduce((acc, controlSet) => {
        const newElements = controlSet.children.filter((controlElement) => {
          return value.includes(controlElement.id);
        });
        acc.push(...newElements);
        return acc;
      }, []);

      if (controlElements && controlElements.length > 0) {
        this.assetDetailsService.setSelectedControlElements(controlElements);
      }
    });
  }

  handleCommandsChange(controlElementId, selected) {
    if (this.curtail && this.curtail.length > 0) {
      if (selected) {
        this.assetDetailsService.addFireOrderStep({ control: controlElementId });
      } else {
        this.assetDetailsService.removeFireOrderStep({ control: controlElementId });
      }
    }
  }

  selectSite(siteId: string) {
    this.resetSiteDependantFields();
    this.assetDetailsService.selectSite(siteId);
    this.loadingEquipment = true;
    
    const selectedSite = this.sites.find((site) => siteId === site.id);
    if(selectedSite) {
      this.site_label_to_display = selectedSite.displayLabel;
    }
  }

  getEquipmentDisplayLabel(equipmentId) {
    const equipment = this.allEquipment.find((equip) => equip.id === equipmentId);
    if (equipment) {
      return equipment.displayLabel;
    }
  }

  resetSiteDependantFields() {
    this.meterList = [];
    this.controlSetList = [];
    this.equipmentList = [];
    if (this.assetForm) {
      this.siteDependantFields.forEach((field) => {
        this.assetForm.get(field).patchValue([]);
      });
      this.curtail.clear();
      this.restore.clear();
    }
    this.assetDetailsService.setSelectedControlElements([]);
    this.assetDetailsService.clearSiteSpaces();
  }

  removeEquipment(equipmentId: string, field: string) {
    const oldValues = this.assetForm.get(field).value;
    const newValues = oldValues.filter((val) => val !== equipmentId);
    this.assetForm.get(field).setValue(newValues);

    if (field === 'commands') {
      this.assetDetailsService.removeFireOrderStep({ control: equipmentId });
    }
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return this.supportedLocales.value.length > num - 1 && !this.supportedLocales.value.includes(locale);
  }

  handleSelectionChange() {
    setTimeout(() => {
      this.triggerValidation();
    }, 100);

    if (this.supportedLocales.value.length === 0) {
      this.supportedLocales.patchValue([...this.supportedLocales.value, this.multiLocaleConfig.defaultLocale]);
    }
  }

  updateSupportedLocales(localeName: string) {
    let locale = this.localesService.checkForLocale(localeName, this.supportedLocales.value);
    if (!locale) {
      let locale = this.localesService.checkForLocale(localeName, this.locales);
      this.supportedLocales.setValue([...this.supportedLocales.value, locale]);
    }
  }

  updateAssetLocaleAndTimezone(siteId: string) {
    const selectedSite = this.sites.find((site) => siteId === site.id);
    if (selectedSite) {
      this.timezone.setValue(selectedSite.timezone || 'America/New_York');
      this.updateSupportedLocales(selectedSite.defaultLocale || 'en_US');
      this.locale.setValue(selectedSite.defaultLocale || 'en_US');
    }
  }

  buildSupportedLocales() {
    if (this.supportedLocales.value && this.displayLabels.value && this.locales) {
      this.supportedLocales.patchValue([]);
      const keys = Object.keys(this.displayLabels.value);
      for (let key of keys) {
        const localeFound = this.locales.find((locale) => locale.localeName === key);
        if (localeFound) {
          this.supportedLocales.patchValue([...this.supportedLocales.value, localeFound]);
        }
      }
    }
  }

  triggerValidation() {
    this.displayLabels.updateValueAndValidity();
  }

  private async loadDynamicLists() {
    this.localesService.setLocales();
    this.timezonesService.setTimezones();
    if (this.orgId) {
      if (!this.initialEdit) {
        this.resetSiteDependantFields();
      }
      this.loadingSites = true;
      await this.assetDetailsService.getSitesForOrg(this.orgId);
      this.loadingSites = false;
      this.initialEdit = false;

      const selectedSite = this.sites.find((site) => this.currentSite.id === site.id);
      if(selectedSite) {
        this.site_label_to_display = selectedSite.displayLabel;
      }
    }
  }
}
