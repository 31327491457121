<div ngModelGroup="app-asset" id="assetForm" [formGroup]="assetForm">
    <div class="form-group">
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'asset.create.supported_locales' | translate }}</mat-label>
            <mat-select
              required
              formControlName="supportedLocales"
              data-automation="supportedLocales"
              placeholder="{{ 'asset.create.placeholder.supported_locales' | translate }}"
              (selectionChange)="handleSelectionChange()"
              multiple
            >
                <mat-option *ngFor="let locale of locales" [value]="locale" [disabled]="limitLocaleSelectList(5, locale)">
                    {{ locale.displayLabel }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'asset.create.default_locale' | translate }}</mat-label>
            <mat-select
              required
              formControlName="defaultLocale"
              data-automation="locale"
              placeholder="{{ 'asset.create.placeholder.default_locale_selector' | translate }}"
            >
                <mat-option *ngFor="let supportedLocale of assetForm.controls.supportedLocales.value" [value]="supportedLocale.localeName">
                    {{ supportedLocale.displayLabel }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'asset.create.timezone' | translate }}</mat-label>
            <mat-select
              required
              formControlName="timezone"
              data-automation="timezone"
              placeholder="{{ 'asset.create.placeholder.timezone_selector' | translate }}"
            >
                <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezoneName">
                    {{ timezone.displayLabel }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="mat-form-field-container" data-automation="multilocale_display_labels">
            <multi-locale-input
              (onChange)="triggerValidation()"
              formControlName="displayLabels"
              [inputLabel]="nameLabel"
              [locales]="supportedLocales.value"
              [displayLabelKey]="multiLocaleConfig.displayLabelKey"
              [localeKey]="multiLocaleConfig.localeKey"
              [defaultLocale]="multiLocaleConfig.defaultLocale"
              [placeholderText]="namePlaceholder"
              [required]="true"
              [id]="'asset_display_labels'"
              >
            </multi-locale-input>
        </div>

        <mat-form-field appearance="outline" [floatLabel]="loadingSites ? 'never' : 'always'">
            <mat-label>{{ 'asset.create.site' | translate }}</mat-label>
            <mat-select
                id="site"
                required
                data-url-persist
                [class.loading-field]="loadingSites"
                (selectionChange)="selectSite($event.value)"
                formControlName="site"
                data-automation="siteSelector"
                [placeholder]="
                    (sites.length > 0 ? 'asset.create.placeholder.site_selector' : 'asset.create.placeholder.no_sites')
                        | translate
                "
                matTooltip="{{ site_label_to_display }}"
                matTooltipPosition="above"
            >
                <mat-option *ngFor="let site of sites" [value]="site.id"
                matTooltip="{{ site.displayLabel }}" matTooltipPosition="above">
                    {{ site.displayLabel }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="site.hasError('required')">
                {{ 'asset.validation.required' | translate }}
            </mat-error>
            <mat-hint *ngIf="loadingSites" align="end">{{ 'asset.loading' | translate }}</mat-hint>
            <mat-spinner *ngIf="loadingSites" matSuffix diameter="23"></mat-spinner>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'asset.create.status' | translate }}</mat-label>
            <mat-select
                required
                formControlName="status"
                data-automation="status"
                placeholder="{{ 'asset.create.placeholder.status' | translate }}"
            >
                <mat-option *ngFor="let status of statuses" [value]="status.code">
                    {{ status.displayLabel | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <h4 class="form-header">{{ 'asset.create.monitors' | translate }}</h4>
    <div class="form-group">
        <div class="mat-form-field-container">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'asset.create.measured_points' | translate }}</mat-label>
                <mat-select
                    #monitorSelector
                    multiple
                    formControlName="monitors"
                    data-automation="monitors"
                    [class.loading-field]="loadingEquipment"
                    [placeholder]="
                        (meterList.length > 0
                            ? 'asset.create.placeholder.measured_points'
                            : 'asset.create.placeholder.no_measured_points'
                        ) | translate
                    "
                >
                    <ng-container *ngFor="let meter of meterList">
                        <mat-optgroup *ngIf="meter.children && meter.children.length > 0" [label]="meter.displayLabel">
                            <mat-option *ngFor="let point of meter.children" [value]="point.id">
                                {{ point.displayLabel }}
                            </mat-option>
                        </mat-optgroup>
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="monitors.hasError('required')">
                    {{ 'asset.validation.required' | translate }}
                </mat-error>
                <mat-hint *ngIf="loadingEquipment" align="end">{{ 'asset.loading' | translate }}</mat-hint>
                <mat-spinner *ngIf="loadingEquipment" matSuffix diameter="23"></mat-spinner>
                <mat-hint *ngIf="!loadingEquipment">{{ 'asset.monitors_hint' | translate }}</mat-hint>
            </mat-form-field>
            <lib-ngx-deeplinker [config]="deeplinkerMeterConfig"></lib-ngx-deeplinker>
        </div>
        <ul *ngIf="monitors.value && monitors.value.length > 0" class="selected-list">
            <li *ngFor="let point of monitors.value">
                <div class="remove-btn" (click)="removeEquipment(point, 'monitors')">
                    <mat-icon size="12">remove_circle</mat-icon>
                </div>
                <div class="label">
                    {{ getEquipmentDisplayLabel(point) }}
                </div>
            </li>
        </ul>
    </div>

    <h4 class="form-header">{{ 'asset.create.performance' | translate }}</h4>
    <div class="form-group">
        <div class="mat-form-field-container">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'asset.create.measured_points' | translate }}</mat-label>
                <mat-select
                    multiple
                    formControlName="performance"
                    data-automation="performance"
                    [class.loading-field]="loadingEquipment"
                    [placeholder]="
                        (meterList.length > 0
                            ? 'asset.create.placeholder.measured_points'
                            : 'asset.create.placeholder.no_measured_points'
                        ) | translate
                    "
                >
                    <ng-container *ngFor="let meter of meterList">
                        <mat-optgroup *ngIf="meter.children && meter.children.length > 0" [label]="meter.displayLabel">
                            <mat-option *ngFor="let point of meter.children" [value]="point.id">
                                {{ point.displayLabel }}
                            </mat-option>
                        </mat-optgroup>
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="performance.hasError('required')">
                    {{ 'asset.validation.required' | translate }}
                </mat-error>
                <mat-hint *ngIf="loadingEquipment" align="end">{{ 'asset.loading' | translate }}</mat-hint>
                <mat-spinner *ngIf="loadingEquipment" matSuffix diameter="23"></mat-spinner>
                <mat-hint *ngIf="!loadingEquipment">{{ 'asset.performance_hint' | translate }}</mat-hint>
            </mat-form-field>
            <lib-ngx-deeplinker [config]="deeplinkerMeterConfig"></lib-ngx-deeplinker>
        </div>
        <ul *ngIf="performance.value && performance.value.length > 0" class="selected-list">
            <li *ngFor="let point of performance.value">
                <div class="remove-btn" (click)="removeEquipment(point, 'performance')">
                    <mat-icon size="12">remove_circle</mat-icon>
                </div>
                <div class="label">
                    {{ getEquipmentDisplayLabel(point) }}
                </div>
            </li>
        </ul>
    </div>

    <h4 class="form-header">{{ 'asset.create.submeters' | translate }}</h4>
    <div class="form-group">
        <div class="mat-form-field-container">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'asset.create.measured_points' | translate }}</mat-label>
                <mat-select
                        multiple
                        formControlName="submeters"
                        data-automation="submeters"
                        [class.loading-field]="loadingEquipment"
                        [placeholder]="
                        (meterList.length > 0
                            ? 'asset.create.placeholder.measured_points'
                            : 'asset.create.placeholder.no_measured_points'
                        ) | translate
                    "
                >
                    <ng-container *ngFor="let meter of meterList">
                        <mat-optgroup *ngIf="meter.children && meter.children.length > 0" [label]="meter.displayLabel">
                            <mat-option *ngFor="let point of meter.children" [value]="point.id">
                                {{ point.displayLabel }}
                            </mat-option>
                        </mat-optgroup>
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="submeters.hasError('required')">
                    {{ 'asset.validation.required' | translate }}
                </mat-error>
                <mat-hint *ngIf="loadingEquipment" align="end">{{ 'asset.loading' | translate }}</mat-hint>
                <mat-spinner *ngIf="loadingEquipment" matSuffix diameter="23"></mat-spinner>
                <mat-hint *ngIf="!loadingEquipment">{{ 'asset.submeters_hint' | translate }}</mat-hint>
            </mat-form-field>
            <lib-ngx-deeplinker [config]="deeplinkerMeterConfig"></lib-ngx-deeplinker>
        </div>
        <ul *ngIf="submeters.value && submeters.value.length > 0" class="selected-list">
            <li *ngFor="let point of submeters.value">
                <div class="remove-btn" (click)="removeEquipment(point, 'submeters')">
                    <mat-icon size="12">remove_circle</mat-icon>
                </div>
                <div class="label">
                    {{ getEquipmentDisplayLabel(point) }}
                </div>
            </li>
        </ul>
    </div>

    <h4 class="form-header">{{ 'asset.create.commands' | translate }}</h4>
    <div class="form-group">
        <div class="mat-form-field-container">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'asset.create.control_elements' | translate }}</mat-label>
                <mat-select
                    #commandsSelector
                    multiple
                    formControlName="commands"
                    data-automation="commands"
                    [class.loading-field]="loadingEquipment"
                    [placeholder]="
                        (controlSetList.length > 0
                            ? 'asset.create.placeholder.control_elements'
                            : 'asset.create.placeholder.no_control_elements'
                        ) | translate
                    "
                >
                    <ng-container *ngFor="let controlSet of controlSetList">
                        <mat-optgroup
                            *ngIf="controlSet.children && controlSet.children.length > 0"
                            [label]="controlSet.displayLabel"
                        >
                            <mat-option #matOption (click)="handleCommandsChange(controlElement.id, matOption.selected)" *ngFor="let controlElement of controlSet.children" [value]="controlElement.id">
                                {{ controlElement.displayLabel }}
                            </mat-option>
                        </mat-optgroup>
                    </ng-container>
                </mat-select>
                <mat-hint *ngIf="loadingEquipment" align="end">{{ 'asset.loading' | translate }}</mat-hint>
                <mat-spinner *ngIf="loadingEquipment" matSuffix diameter="23"></mat-spinner>
            </mat-form-field>

            <lib-ngx-deeplinker [config]="deeplinkerControlSetConfig" id="control_set_deeplink"></lib-ngx-deeplinker>
        </div>

        <ul *ngIf="commands.value && commands.value.length > 0" class="selected-list">
            <li *ngFor="let controlElement of commands.value">
                <div class="remove-btn" (click)="removeEquipment(controlElement, 'commands')">
                    <mat-icon size="12">remove_circle</mat-icon>
                </div>
                <div class="label">
                    {{ getEquipmentDisplayLabel(controlElement) }}
                </div>
            </li>
        </ul>
    </div>

    <h4 class="form-header">{{ 'asset.create.includes' | translate }}</h4>
    <div class="form-group">
        <div class="mat-form-field-container">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'asset.create.equipment' | translate }}</mat-label>
                <mat-select
                    multiple
                    formControlName="includes"
                    data-automation="includes"
                    [class.loading-field]="loadingEquipment"
                    [placeholder]="
                        (equipmentList.length > 0
                            ? 'asset.create.placeholder.equipment'
                            : 'asset.create.placeholder.no_equipment'
                        ) | translate
                    "
                >
                    <mat-option *ngFor="let equipment of equipmentList" [value]="equipment.id">
                        {{ equipment.displayLabel }}
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="loadingEquipment" align="end">{{ 'asset.loading' | translate }}</mat-hint>
                <mat-spinner *ngIf="loadingEquipment" matSuffix diameter="23"></mat-spinner>
            </mat-form-field>

            <lib-ngx-deeplinker [config]="deeplinkerMachineConfig" id="machine_deeplink"></lib-ngx-deeplinker>
        </div>
        <ul *ngIf="includes.value && includes.value.length > 0" class="selected-list">
            <li *ngFor="let equipment of includes.value">
                <div class="remove-btn" (click)="removeEquipment(equipment, 'includes')">
                    <mat-icon size="12">remove_circle</mat-icon>
                </div>
                <div class="label">
                    {{ getEquipmentDisplayLabel(equipment) }}
                </div>
            </li>
        </ul>
    </div>

    <h4 class="form-header">{{ 'asset.create.fire_order.title' | translate }}</h4>
    <div class="fire-order-section">
        <app-fire-order [assetForm]="assetForm"></app-fire-order>
    </div>
</div>
