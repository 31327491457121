import { Component, OnInit } from '@angular/core';
import { AssetsService } from '../shared/services/assets.service';
import { AssetDetailsService } from '../asset-details/asset-details.service';
import { OrganizationsService } from '../shared/services/organizations.service';
import { Router } from '@angular/router';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { SidenavService } from '../shared/services/sidenav.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  displayedColumns: string[] = ['status', 'display_label', 'monitors', 'commands', 'includes'];
  loading = true;
  noAssets = false;
  dataSource: any[];
  selectedAsset = '';
  isInit = true;

  constructor(
    private router: Router,
    private assetService: AssetsService,
    private assetDetailsService: AssetDetailsService,
    private organizationsService: OrganizationsService,
    private orgSelectorService: ContextSelectorService,
    private sidenavService: SidenavService,
  ) {
    this.orgSelectorService.currentContext$.subscribe(async (orgs: Context[]) => {
      const org = orgs[0];
      if (org && org.id) {
        this.loading = true;
        this.assetService.assets$.next([]);
        await this.assetService.getAssetsByOrg(org.id);
        this.loading = false;
        if (!this.router.isActive('/', true) && !this.router.isActive('/create', true) && !this.isInit) {
          this.router.navigate([`/`], {});
        }
        this.isInit = false;
      }
    });
    this.assetService.assets$.subscribe((assets) => {
      if (assets) {
        this.dataSource = assets;
        this.noAssets = assets.length === 0;
      }
    });
    this.assetDetailsService.selectedAsset.subscribe((assetId) => {
      this.selectedAsset = assetId;
    });
  }

  async ngOnInit() {}

  onRowClicked(id) {
    this.sidenavService.closeIfMobile();
    this.router.navigate([`details/${id}/view`], {});
  }

  routeToCreatePage() {
    this.sidenavService.closeIfMobile();
    this.router.navigate([`create`], {});
  }
}
