import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DeeplinksService {
  deeplink$: BehaviorSubject<string>;

  constructor(private apiService: ApiService) {
    this.deeplink$ = new BehaviorSubject<string>('');
  }

  async setEquipmentAdminUrl() {
    try {
      const url = await this.apiService.get('deeplinks/equipment_admin');
      this.deeplink$.next(url);
    } catch (err) {
      console.log('Could not load equipment admin url.', err);
    }
  }
}
