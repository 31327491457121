<div>
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon>library_add</mat-icon>
                </div>
                <h1 id="create-title" data-automation="createTitle">{{ 'asset.create.page_title' | translate }}</h1>
                <p class="subtitle" data-automation="subtitle">{{ 'asset.create.page_subtitle' | translate }}</p>
            </div>
        </div>

        <div class="right-column">
            <button
                class="icon-btn x-btn-secondary"
                aria-label="Cancel"
                (click)="handleCancel()"
                data-automation="cancel"
            >
                <mat-icon>clear</mat-icon>
                <span class="btn-text"> {{ 'asset.edit.button.cancel' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                aria-label="Submit"
                (click)="handleSubmit()"
                [disabled]="!assetForm.valid"
                type="submit"
                data-automation="submit"
            >
                <mat-icon *ngIf="!isSubmitting">done</mat-icon>
                <div *ngIf="isSubmitting" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text">{{ 'asset.create.button.submit' | translate }}</span>
            </button>
        </div>
    </header>

    <form #createForm="ngForm" data-app-prefix="ast" class="asset-form">
        <app-asset [mode]="mode" [assetForm]="assetForm"></app-asset>
    </form>
</div>
