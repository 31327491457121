<div class="checks-wrapper" [formGroup]="step">
  <mat-label class="check-label" *ngIf="checks && checks.length > 0">{{ 'asset.create.fire_order.checks.title' | translate }}</mat-label>
  <div class="check-wrapper" *ngIf="checks && checks.length > 0" formArrayName="checks">
    <div *ngFor="let check of checks.controls; let checkIndex = index">
      <div class="fire-order-group check-group" [formGroup]="check" data-automation="checks">
        <mat-icon (click)="removeCheck(checkIndex)" class="delete-check" data-automation="delete-check" title="{{'asset.create.fire_order.delete_check' | translate }}">delete</mat-icon>
        <mat-form-field appearance="outline" floatLabel="always" class="double-width">
          <mat-label>{{ 'asset.create.fire_order.checks.space_id' | translate }}</mat-label>
          <mat-select
            formControlName="spaceId"
            data-automation="space"
            placeholder="{{ (spaces ? 'asset.create.placeholder.fire_order.space_id' : 'asset.create.placeholder.fire_order.no_space_id') | translate }}"
            required
          >
            <mat-optgroup *ngFor="let group of spaces | keyvalue" [label]="group.key">
              <mat-option
                *ngFor="let space of group.value"
                [value]="space.id"
                id="space_{{ space.id }}"
              >{{ space.displayLabel }}
              </mat-option>
            </mat-optgroup>
          </mat-select>
          <mat-error *ngIf="check.get('spaceId').hasError('required')">
            {{ 'asset.validation.required' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ 'asset.create.fire_order.checks.type' | translate }}</mat-label>
          <mat-select
            id="type"
            formControlName="type"
            data-automation="checkType"
            placeholder="{{ 'asset.create.fire_order.checks.type' | translate }}"
            [compareWith]="compareCheckTypes"
            required
          >
            <ng-container *ngFor="let type of fireOrderCheckTypes">
              <mat-option [value]="type.code.toLowerCase()">{{ type.displayLabel }}</mat-option>
            </ng-container>
          </mat-select>
          <mat-error *ngIf="check.get('type').hasError('required')">
            {{ 'asset.validation.required' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ 'asset.create.fire_order.checks.channel' | translate }}</mat-label>
          <mat-select
            formControlName="channelId"
            data-automation="channel"
            placeholder="{{ 'asset.create.fire_order.checks.channel' | translate }}"
            required
          >
            <ng-container *ngFor="let channel of channels">
              <mat-option [value]="getNumericChannelId(channel.id)">{{ channel.displayLabel }}</mat-option>
            </ng-container>
          </mat-select>
          <mat-error *ngIf="check.get('channelId').hasError('required')">
            {{ 'asset.validation.required' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ 'asset.create.fire_order.checks.period' | translate }}</mat-label>
          <mat-select
            formControlName="granularity"
            data-automation="granularity"
            placeholder="{{ 'asset.create.fire_order.checks.period' | translate }}"
            required
          >
            <ng-container *ngFor="let period of reportingIntervals">
              <mat-option [value]="period.code">{{ period.displayLabel }}</mat-option>
            </ng-container>
          </mat-select>
          <mat-error *ngIf="check.get('granularity').hasError('required')">
            {{ 'asset.validation.required' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ 'asset.create.fire_order.checks.operator' | translate }}</mat-label>
          <mat-select
            formControlName="operator"
            data-automation="operator"
            placeholder="{{ 'asset.create.fire_order.checks.operator' | translate }}"
            required
          >
            <ng-container *ngFor="let operator of equalityOperators">
              <mat-option [value]="operator.code">{{ operator.displayLabel }}</mat-option>
            </ng-container>
          </mat-select>
          <mat-error *ngIf="check.get('operator').hasError('required')">
            {{ 'asset.validation.required' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ 'asset.create.fire_order.checks.threshold' | translate }}</mat-label>
          <input
            matInput
            type="number"
            min="0"
            step=".1"
            formControlName="threshold"
            data-automation="threshold"
            placeholder="{{ 'asset.create.fire_order.checks.threshold' | translate }}"
            required
          />
          <mat-error *ngIf="check.get('threshold').hasError('required')">
            {{ 'asset.validation.required' | translate }}
          </mat-error>
          <mat-error *ngIf="check.get('threshold').hasError('min')">
            {{ 'asset.validation.greater_than_zero' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <button class="button add-check-button" (click)="addCheck()" data-automation="add-check" title="{{'asset.create.fire_order.add_check' | translate }}">{{ 'asset.create.fire_order.add_check' | translate}}</button>
</div>

