import { Component, Input, OnInit } from '@angular/core';
import { Asset, FireOrder } from '../../shared/model/asset.model';
import { RefService } from '../../shared/services/ref.service';

@Component({
  selector: 'app-fire-order-view',
  templateUrl: './fire-order-view.component.html',
  styleUrls: ['./fire-order-view.component.scss'],
})
export class FireOrderViewComponent implements OnInit {
  curtailOpenState: boolean = false;
  restoreOpenState: boolean = false;

  constructor(private refService: RefService) {}

  @Input() asset: Asset;

  get fireOrder() {
    return (this.asset.fireOrder || {}) as FireOrder;
  }

  get curtail() {
    return this.fireOrder && this.fireOrder.curtail ? this.fireOrder.curtail : [];
  }

  get restore() {
    return this.fireOrder && this.fireOrder.restore ? this.fireOrder.restore : [];
  }

  ngOnInit(): void {
    this.refService.setChannels();
    this.refService.setEqualityOperators();
    this.refService.setFireOrderCheckType();
    this.refService.setReportingIntervals();
  }
}
