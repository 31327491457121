<section class="fire-order-section view" *ngIf="fireOrder">
  <mat-expansion-panel
    (opened)="curtailOpenState = true"
    (closed)="curtailOpenState = false"
    togglePosition="before"
    expanded="true"
    data-automation="curtail"
    *ngIf="curtail && curtail.length > 0"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4 class="fire-order-subheader">{{ "asset.create.fire_order.curtail" | translate }}</h4>
      </mat-panel-title>
    </mat-expansion-panel-header>
      <div class="fire-order-step-wrapper">
        <ng-container *ngFor="let step of curtail; let stepIndex = index">
          <app-fire-order-view-step [step]="step" [index]="stepIndex"></app-fire-order-view-step>
        </ng-container>
      </div>
  </mat-expansion-panel>

  <mat-expansion-panel
    (opened)="restoreOpenState = true"
    (closed)="restoreOpenState = false"
    togglePosition="before"
    expanded="true"
    data-automation="restore"
    *ngIf="restore && restore.length > 0"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4 class="fire-order-subheader">{{ "asset.create.fire_order.restore" | translate }}</h4>
      </mat-panel-title>
    </mat-expansion-panel-header>
      <div class="fire-order-step-wrapper">
        <ng-container *ngFor="let step of restore; let stepIndex = index">
          <app-fire-order-view-step [step]="step" [index]="stepIndex"></app-fire-order-view-step>
        </ng-container>
      </div>
  </mat-expansion-panel>
</section>
<div *ngIf="curtail.length === 0 && restore.length == 0">{{ 'asset.create.fire_order.no_fire_order_configured' | translate }}</div>
