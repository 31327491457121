import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { Asset } from '../model/asset.model';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AssetsService {
  readonly assets$ = new BehaviorSubject<Asset[]>(null);
  readonly orgId$ = new BehaviorSubject<string>(null);

  constructor(private apiService: ApiService) {}

  async getAsset(id: string) {
    try {
      const asset = await this.apiService.get(`flexible-assets/${id}`);
      const newAsset: Asset = convertKeys.toCamel(asset);
      newAsset.displayLabels = asset.display_labels;
      return newAsset;
    } catch (err) {
      console.log('Could not load equipment.', err);
    }
  }

  async getAssetsByOrg(orgId: string) {
    try {
      const assets = await this.apiService.get(`flexible-assets?org_id=${orgId}`);
      this.assets$.next(convertKeys.toCamel(assets));
      // need to be removed after fixing api result
      this.assets$.subscribe(value => {
        if(value){
          value.map(ele => {
            this.getAsset(ele.id).then((rs=> {
              ele.commands = rs.commands;
              ele.includes = rs.includes
            }));
            
          })
        }
      });
      this.orgId$.next(orgId);
    } catch (err) {
      console.log(`Could not load Assets for Org: ${orgId}`, err);
      this.assets$.next([]);
    }
  }

  async refetchAssets() {
    if (this.orgId$.value) {
      await this.getAssetsByOrg(this.orgId$.value);
    }
  }

  async createAsset(asset: Asset): Promise<Asset> {
    try {
      const dto = convertKeys.toSnake<any>(asset);
      dto.display_labels = asset.displayLabels;
      const createdAsset = await this.apiService.post('flexible-assets', dto);
      return convertKeys.toCamel<any>(createdAsset);
    } catch (err) {
      console.log(`Could not Create Asset`, err);
    }
  }

  async deleteAsset(assetId: string) {
    const deletedAsset = await this.apiService.delete(`flexible-assets/${assetId}`);
    return convertKeys.toCamel<any>(deletedAsset);
  }

  async updateAsset(asset: Asset) {
    try {
      const id = asset.id;
      const dto = convertKeys.toSnake<any>(asset);
      dto.display_labels = asset.displayLabels;
      const updatedAsset = await this.apiService.put(`flexible-assets/${id}`, dto);
      return convertKeys.toCamel<any>(updatedAsset);
    } catch (err) {
      console.log(`Could not Update Asset`, err);
    }
  }

  async getOrgForSite(siteId: string) {
    try {
      return await this.apiService.get(`orgs/${siteId}`);
    } catch (err) {
      console.log(`Could not Update Asset`, err);
      return Promise.reject(err);
    }
  }

  newAsset(rawAsset: any) {
    const asset = convertKeys.toCamel<any>(rawAsset);
    asset.alternateIds = asset.alternateIds || {};
  }
}
